import React from 'react';
import {Button, Input, Layout, Modal} from "antd";
import {Content} from "antd/es/layout/layout";
import './style.scss'

const MBModal = (props) => {
    const {
        isModalOpen,
        setIsModalOpen,
        albumName,
        handleInputChange,
        handleConfirmClick,
        isNewAlbum,
    } = props
    return (
        <Modal
            className={isNewAlbum ? 'ml-new-album-modal' :
                'ml-new-album-modal ml-new-album-modal-rename'}
            visible={isModalOpen}
            onOk={_ => setIsModalOpen(false)}
            onCancel={_ => setIsModalOpen(false)}
        >
            {isNewAlbum ?
                <div>
                    <p className={'ml-new-album-modal-title'}>Create a New Album</p>
                    <p className={'ml-new-album-modal-txt'} style={{
                        width: '30vw'
                    }}>
                        For an enhanced viewing experience, please ensure that the orientation of your images corresponds to the album to which you upload them. Utilise portrait images for portrait Albums and landscape images for landscape Albums
                    </p>
                </div> : <></>}

            {isNewAlbum ? <p className={'ml-new-album-modal-sub-title'}>Give Your Album a Name</p> :
                <p className={'ml-new-album-modal-sub-title'}>Rename Your Album</p>}
            <Input className={'ml-new-album-modal-input'}
                   style={{width: '20vw', margin: '2vh 0 0 9vw'}}
                   placeholder="Album Name"
                   value={albumName} // Bind input value to state
                   onChange={handleInputChange} // Set up onChange handler
            />
            <Button className={'ml-new-album-modal-btn'}
                    style={{
                        width: '13vw',
                        height: '6vh',
                        margin: '4vh 0 0 12vw'
                    }}
                    onClick={handleConfirmClick}>
                Confirm
            </Button>
        </Modal>
    )
}

export default MBModal;
