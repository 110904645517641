import React, {useState} from 'react';
import {Button, Checkbox, Col, Row} from 'antd';
import { Input, Radio, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
const options = [
    { label: 'I have read and understand the purpose of this research and my part in it.', value: 'A' },
    { label: 'I am over the age of 18 and in sound mental health.', value: 'B' },
    { label: 'I understand how the data from my participation will be used.', value: 'C' },
    { label: 'I understand that I can contact the investigator at any time with queries or concerns.', value: 'D' },
    { label: 'I have the right to withdraw my data at any point during or after the interview up until the deadline date and understand that all materials will be destroyed.', value: 'E' },
    { label: 'I voluntarily agree to take part in this study.', value: 'F' },
];

const Page2 = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        consent: [],
        participant_code: '',
        laptop: '',
        age:[],
        ethnicity: '',
        Gender: '',
        residence: '',
        spaces: '',
        spaces2: '',
        fluency: '',
    });
    const onChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: name === 'age' ? parseInt(value, 10) : value,
        });
    };
    const handleCheckboxChange = (consent) => {
        onChange('consent', consent);
    };
    const handleRadioChange = (name, e) => {
        // Convert the value to String before assigning
        onChange(name, e.target.value);
    };
    const handleSubmit = () => {
        // 检查是否所有的复选框都被选中
        const allOptionsSelected = options.every((option) => formData.consent.includes(option.value));

        if (allOptionsSelected && formData.participant_code.trim() !== '') {
            // 所有选项都被选中，保存数据到本地存储
            localStorage.setItem('formData', JSON.stringify(formData));
            navigate('/page3');
        } else {
            // 提示用户选择所有的复选框
            alert('Please ensure you fill all the compulsory fields and using laptop for this survey');
        }
    };

    // Optionally, add this function to clear local storage when the component mounts
    // useEffect(() => {
    //   localStorage.removeItem('formData');
    // }, []);

    return (
        <div style={{marginTop: '100px',display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start'}}>
            <h2 style={{marginLeft: '700px'}}>DECLARATION OF CONSENT(Please tick all) <span style={{ color: 'red' }}>*</span></h2>
            <Checkbox.Group
                style={{
                    width: '40%',
                    marginLeft: '400px'
                }}
                options={options}
                onChange={handleCheckboxChange}
            />
            <p></p>
            <h2 style={{marginLeft: '700px'}}>Please create a Unique Participant Code.<span style={{ color: 'red' }}>*</span></h2>
            <div style={{marginLeft: '400px'}}>
                This will allow us to link information you provide from the following questionnaire with the information you provide during the task. It will also allow you to withdraw data at a later stage,if you so choose.
            </div>
            <br />
            <div style={{marginLeft: '400px'}}>
                Your code should be made up of the following, in this order:<br />
                1. First 2 letters of your Mother's maiden surname(ie the surname she had at birth)<br />
                2. The day of your birthdate (eg.,if you were born on 25th Dec, write 25)<br />
                3. Last 2 letters of your postcode (eg., if your post code is NG9 7GU, write GU)
            </div>
            <p></p>
            <div style={{marginLeft: '400px'}}>
                Please write your Unique Participant Code below, and record for yourself, as you will need to use it later.<span style={{ color: 'red' }}>*</span>
                <br /><br />
                <Input placeholder="Enter your code"
                       style={{ width: '400px' }}
                       onChange={(e) => onChange('participant_code', e.target.value)}/>
            </div>
            <p></p>
            <div  style={{marginLeft: '400px'}}>
                Are you using a laptop/computer to take the survey?<span style={{ color: 'red' }}>*</span>
                <br /><br />
                <Radio.Group onChange={(e) => handleRadioChange('laptop', e)} >
                    <Space direction="vertical">
                        <Radio value={'Yes'}>Yes</Radio>
                        <Radio value={'No'}>No</Radio>
                    </Space>
                </Radio.Group>
            </div>
            <h2 style={{marginLeft: '750px'}}>PARTICIPATION PROFILE</h2>
            <div style={{marginLeft: '400px'}}>
                Please tell us your age (in years):
                <br /><br />
                <Input placeholder="Enter your age"
                       style={{ width: '400px' }}
                       onChange={(e) => onChange('age', e.target.value)}/>
            </div>
            <p></p>
            <div style={{marginLeft: '400px'}}>
                What is your Ethnicity ?
                <br /><br />
                <Radio.Group onChange={(e) => handleRadioChange('ethnicity', e)} >
                    <Space direction="vertical">
                        <Radio value={'White'}>White</Radio>
                        <Radio value={'Black or African American'}>Black or African American</Radio>
                        <Radio value={'American Indian or Alaska Native'}>American Indian or Alaska Native</Radio>
                        <Radio value={'Asian'}>Asian</Radio>
                        <Radio value={'Native Hawaiian or Pacific Islander'}>Native Hawaiian or Pacific Islander</Radio>
                        <Radio value={'Other'}>Other</Radio>
                    </Space>
                </Radio.Group>
            </div>
            <p></p>
            <div style={{marginLeft: '400px'}}>
                What is your biological sex (ie the sex you were born with)?
                <br /><br />
                <Radio.Group onChange={(e) => handleRadioChange('Gender', e)}  >
                    <Space direction="vertical">
                        <Radio value={'Male'}>Male</Radio>
                        <Radio value={'Female'}>Female</Radio>
                        <Radio value={'Non-binary/third gender'}>Non-binary/third gender</Radio>
                        <Radio value={'Prefer not to say'}>Prefer not to say</Radio>
                    </Space>
                </Radio.Group>
            </div>
            <p></p>
            <div style={{marginLeft: '400px'}}>
                 What village,town or city do you live in?
                <p></p>
                <Input placeholder="Enter the name"
                       style={{ width: '400px' }}
                       onChange={(e) => onChange('residence', e.target.value)}
                />
            </div>
            <p></p>
            <div style={{marginLeft: '400px'}}>
                 Think about where you live,relative to urban spaces(e.g.,buildings and roads), how much nature is there?
                <br /><br />
                <Radio.Group onChange= {(e) => handleRadioChange('spaces', e)} >
                    <Space direction="vertical">
                        <Radio value={'Much more nature than urban spaces'}>Much more nature than urban spaces</Radio>
                        <Radio value={'A little more nature than urban spaces'}>A little more nature than urban spaces</Radio>
                        <Radio value={'About the same nature and urban spaces'}>About the same nature and urban spaces</Radio>
                        <Radio value={'A Little more urban spaces than nature'}>A Little more urban spaces than nature</Radio>
                        <Radio value={'Much more urban spaces than nature'}>Much more urban spaces than nature</Radio>
                    </Space>
                </Radio.Group>
            </div>
            <p></p>
            <div style={{marginLeft: '400px'}}>
                 Thinking about the place where you mostly lived growing up, relative to urban spaces(e.g., buildings and roads),how much nature was there?
                  <br /><br />
                <Radio.Group onChange={(e) => handleRadioChange('spaces2', e)}>
                    <Space direction="vertical">
                        <Radio value={'Much more nature than urban spaces'}>Much more nature than urban spaces</Radio>
                        <Radio value={'A little more nature than urban spaces'}>A little more nature than urban spaces</Radio>
                        <Radio value={'About the same nature and urban spaces'}>About the same nature and urban spaces</Radio>
                        <Radio value={'A Little more urban spaces than nature'}>A Little more urban spaces than nature</Radio>
                        <Radio value={'Much more urban spaces than nature'}>Much more urban spaces than nature</Radio>
                    </Space>
                </Radio.Group>
            </div>
            <p></p>
            <div style={{marginLeft: '400px'}}>
                How fluent are you in English language?
                <br /><br />
                <Radio.Group onChange={(e) => handleRadioChange('fluency', e)} >
                    <Space direction="vertical">
                        <Radio value={'Native Speaker'}>Native Speaker</Radio>
                        <Radio value={'Fluent Speaker'}>Fluent Speaker</Radio>
                        <Radio value={'Advanced Speaker'}>Advanced Speaker</Radio>
                        <Radio value={'Beginner'}>Beginner</Radio>
                    </Space>
                </Radio.Group>
            </div>
            <p></p>
            <div style={{marginLeft: '900px'}}>
                <Button type="primary" onClick={handleSubmit} style={{ alignSelf: 'flex-end' }}>
                    Next
                </Button>
            </div>
        </div>
    );
};

export default Page2;
