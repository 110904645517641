import React from 'react';
import {Link, redirect, useNavigate} from 'react-router-dom';
import {Layout, Col, Row, Card, Space, Button} from 'antd';
import '../../App.css';
import "../Textura/Textura.css"
import "./HomePage.scss";
import "./HomePageJun.scss";
import {Content} from "antd/es/layout/layout";
import {DiscoverText, HOME_TEXT, TEXTURA_TEXT} from "../../text.js";
import landscape_vd from "../../img/HomePage/1 - Header Video/Vieunite Launch - Homepage - Landscape 2.mp4";
import section2_img from "./img/desktop-album-evergreen-1x 1.png"
import section3_1_img from "./img/Group 1904.png"
import section3_2_img from "./img/Mask group.png"
import vertical_vd from "../../img/HomePage/1 - Header Video/3334ce07-9121-4893-ba16-c3d5db436bc3.mp4";
import {HomepageMeta} from "../../components/PageMeta/HomepageMeta";

import NTULogo from "../../img/HomePage/9 - Logos/ntu.svg"
import UKTILogo from "../../img/HomePage/9 - Logos/innovate - 2024.svg"
import RBSALogo from "../../img/HomePage/9 - Logos/rbsa.svg"
import RoyalLogo from "../../img/HomePage/9 - Logos/the royal society.svg"
import section1_pic from "./img/section_1.png"
import homeExample1 from "./img/homeExample1.png"
import homeExample2 from "./img/homeExample2.png"
import homeExample3 from "./img/homeExample3.png"

const Primary_Font = {
    fontFamily: "Proxima Nova bold, sans-serif",
}

const TitleFont = {
    fontSize: '38px',
    fontFamily: "Proxima Nova bold, sans-serif",
}

const Img_Radius = {
    borderRadius: '0.625rem',
}

function HomePage() {
    // For SSG through "react-snap", can't assign the "mute" property directly on <video> tags.
    // So using the following function to do it
    const makeMuted = (elt) => {
        if (elt) {
            elt.muted = true;
        }
    };

    const navigate = useNavigate();
    return (
        <div>
            <HomepageMeta></HomepageMeta>
            <Layout>
                <Content style={{minWidth: "950px"}}>
                    <div className="homeVideoContainer">
                        <video playsInline loop autoPlay ref={makeMuted} muted rel={"preload"}>
                            <source src={landscape_vd} type={'video/webm'}></source>
                        </video>
                    </div>
                    <div className="homeVideoTextContainer">
                        <p>Bringing art to life with the<br/>Textura Canvas</p>
                        <button className="homeMainButton" style={{width: "20vw", minWidth: "200px"}}>
                            <a className={'homeMainButtonLnk homeOverV'} href={"/digital-canvas"}>
                                LEARN MORE
                            </a>
                        </button>
                    </div>
                    <Content style={{width: '100%', backgroundColor: '#f4f4f4'}}>
                        {/*What is Vieunite*/}
                        <Row>
                            <p className="homeDescriptionText">
                                {HOME_TEXT["Home Section title"]}
                            </p>
                        </Row>
                        <Row className="homeSection1">
                            <Col span={12} style={{padding: "5%"}}>
                                <div className="section1TextContainer">
                                    <p className="homeSection1TitleText">Texture Accuracy Excellence.</p>
                                    <p className="homeSection1BodyText">{HOME_TEXT["Home Section 1"]}</p>
                                    <button className="homeSection1Button">
                                        <a className={'homeSection1ButtonLnk homeOverV'} href={"/digital-canvas"}>
                                            LEARN MORE
                                        </a>
                                    </button>
                                </div>
                            </Col>
                            <Col span={12}>
                                <img src={section1_pic} alt={'preview'}
                                     style={{width: "100%", height: "100%", objectFit: "cover"}}/>
                            </Col>
                        </Row>

                        <Row className="homeSection2">
                            <div style={{width: "25%", padding: "5%"}}>
                                <p className="homeSection1TitleText" style={{textAlign: "center"}}>Simplicity</p>
                                <p className="homeSection1BodyText" style={{textAlign: "center"}}>Textura was designed
                                    with user accessibility in mind
                                    and offers a simple setup and install process that requires little to no technical
                                    knowledge.</p>
                            </div>

                            <div style={{width: "25%", padding: "5%"}}>
                                <p className="homeSection1TitleText" style={{textAlign: "center"}}>Accuracy</p>
                                <p className="homeSection1BodyText" style={{textAlign: "center"}}>The advanced
                                    texture-accurate display technology perfectly replicates artistic finishes such as
                                    brush strokes and preserves them for a lifelike aesthetic.</p>
                            </div>

                            <div style={{width: "25%", padding: "5%"}}>
                                <p className="homeSection1TitleText" style={{textAlign: "center"}}>Efficiency</p>
                                <p className="homeSection1BodyText" style={{textAlign: "center"}}>Schedule times for the
                                    digital canvas to turn on and off automatically, only using power when needed and
                                    improving the energy efficiency.</p>
                            </div>

                            <div style={{width: "25%", padding: "5%"}}>
                                <p className="homeSection1TitleText" style={{textAlign: "center"}}>Transparency</p>
                                <p className="homeSection1BodyText" style={{textAlign: "center"}}>There are no
                                    subscription costs associated with the Textura digital canvas or the Vieunite app
                                    for the entire lifetime of the product.</p>
                            </div>
                        </Row>

                        <Row className="homeSection3">
                            <div className="homeSection3Background">
                                <img src={section2_img} alt={'preview'}/>
                            </div>

                            <div style={{width: "40%", minWidth: "400px", padding: "3%", position: "absolute", height: "650px"}}>
                                <div className="section3TextContainer" style={{color: "white"}}>
                                    <button className="homeMainButton" style={{width: "100%"}}>
                                        <a className={'homeMainButtonLnk homeOverV'} href={"/digital-canvas"}>
                                            LEARN MORE
                                        </a>
                                    </button>
                                    <p className="homeSection1BodyText">{HOME_TEXT["Home Section 2"]}</p>
                                    <p className="homeSection1TitleText">Huge collection of exceptional artwork.</p>
                                </div>
                            </div>
                        </Row>

                        <Row className={'logo-row'} justify={"center"}
                             style={{backgroundColor: '#f4f4f4', padding: '6rem 0 6rem 0'}}>
                            <Col className={'dt-logos'} span={24} lg={20} xs={20}>
                                <h2 className="title-logo" style={{
                                    fontSize: '1.5rem',
                                    paddingBottom: '4rem',
                                    lineHeight: '36px',
                                }}
                                >
                                    IN PARTNERSHIP WITH
                                </h2>
                                <Row justify={"center"} style={{gap: '0'}}>
                                    <Col lg={6} xs={0}
                                         style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <img onClick={() => {
                                            window.open("https://www.ukri.org/councils/innovate-uk/")
                                        }}
                                             alt={'logo'} className={'logo-img'} src={UKTILogo}></img>
                                    </Col>
                                    <Col lg={6} xs={0}
                                         style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <img onClick={() => {
                                            window.open("https://rbsa.org.uk/")
                                        }}
                                             alt={'logo'} className={'logo-img'} src={RBSALogo}></img>
                                    </Col>
                                    <Col lg={6} xs={0}
                                         style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <img onClick={() => {
                                            window.open("https://www.ntu.ac.uk/study-and-courses/academic-schools/art-and-design")
                                        }}
                                             alt={'logo'} className={'logo-img'} src={NTULogo}></img>
                                    </Col>
                                    <Col lg={6} xs={0}
                                         style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <img onClick={() => {
                                            window.open("https://royalsociety.org/")
                                        }}
                                             alt={'logo'} className={'logo-img'} src={RoyalLogo}></img>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row >
                            <Col span={12} style={{padding: "0 3vw 2vw 10vw"}}>
                                <img src={section3_1_img} alt={'preview'} style={{width: "100%", paddingBottom: "20px"}}/>
                                <p className="homeSection1TitleText">Access Curated Art and Artists.</p>
                                <p className="homeSection1BodyText">{HOME_TEXT["Home Section 4_1"]}</p>
                            </Col>
                            <Col span={12} style={{padding: "0 10vw 2vw 3vw"}}>
                                <img src={section3_2_img} alt={'preview'} style={{width: "100%", paddingBottom: "20px"}}/>
                                <p className="homeSection1TitleText">Display Personal Photos and Videos.</p>
                                <p className="homeSection1BodyText">{HOME_TEXT["Home Section 4_2"]}</p>
                            </Col>
                        </Row>

                        <Row>
                            <div className="homeTexturaExampleContainer">
                                <p className="homeSection1TitleText" style={{color: "white"}}>See Textura in Person</p>
                                <img src={homeExample1} alt={'example1'}
                                     style={{width: "33%", paddingBottom: "20px", paddingRight: "1.1vw"}}/>
                                <img src={homeExample2} alt={'example2'}
                                     style={{width: "33%", paddingBottom: "20px", paddingRight: "1.1vw"}}/>
                                <img src={homeExample3} alt={'example3'}
                                     style={{width: "33%", paddingBottom: "20px", paddingRight: "1.1vw"}}/>
                            </div>
                        </Row>

                        <Row>
                        <div className="homeSupportTextContainer">
                                <p className="homeSection1TitleText" style={{textAlign: "center"}}>Get Support</p>
                                <p className="homeSupportBody">If you have questions about the Textura digital canvas or Vieunite app that aren't
                                    answered here, or if you encounter any issues with your product, explore our
                                    dedicated support resources or reach out to us for assistance.</p>
                                <button className="homeSupportButton">
                                    <a className={'homeSupportButtonLnk homeOverV'} href={"/digital-canvas"}>
                                        ACCESS SUPPORT
                                    </a>
                                </button>
                            </div>
                        </Row>
                    </Content>
                </Content>
            </Layout></div>
    );
}

export default HomePage;