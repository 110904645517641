import React, {useEffect} from 'react';
import {Form, Input, Button, message} from 'antd';
import './style.scss'
import {Link} from "react-router-dom";
import ProLogo from './imgs/ProLogo.svg';
import { useLocation } from 'react-router-dom';
import {post_api} from "../../portal/serverTemp";


const ProCongratulations = () => {
    const location = useLocation();
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const secret = queryParams.get('secret');
        console.log('The secret is:', secret);
        post_api('/user/verify/email/last/step',{secret:secret})
            .then(()=>{
                // message.success('Your email has been verified!')
            })
    }, [])

    return (
        <div style={{marginTop: '5vw'}}>
            <div className="ProLogo" style={{marginBottom: '150px'}}>
                <img src={ProLogo} alt="ProLogo" className='ProLogo'/>
            </div>
            <div className="Title" style={{marginTop: '100px'}}>
                Congratulations!
            </div>
            <div className='ProCongratulations-Content'>
                <p className="ProCongratulations-text">
                    You have successfully set up your Vieunite Business account. Please click the button below to log
                    into your account
                </p>
                <div className="ProCongratulations-custom-OK-Button">
                    <Button type="primary"
                            onClick={_=>window.location.href='/business/prosignin'}
                            className="ProCongratulations-OK-Button">
                        Login
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ProCongratulations;
