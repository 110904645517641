import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Button, Progress} from 'antd';

const ImagePage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {state} = location;
    console.log(state)
    const {imageUrls} = state || {};
    const [currentIndex, setCurrentIndex] = useState(0);
    const [progress, setProgress] = useState(0);

    let lastIndex;  // Declare lastIndex outside the useEffect

    useEffect(() => {
        // Check if imageUrls is available and not empty
        if (imageUrls && imageUrls.length > 0) {
            lastIndex = imageUrls.length - 1; // Set lastIndex here

            const intervalId = setInterval(() => {
                setCurrentIndex((prevIndex) => (prevIndex < lastIndex ? prevIndex + 1 : 0));
                setProgress((currentIndex + 1) * (100 / (lastIndex + 1)));
            }, 5000);

            // Clear the interval when the component is unmounted or when currentIndex reaches lastIndex
            return () => {
                clearInterval(intervalId);
                //     setProgress(0); // Reset progress bar when navigation occurs
                // Check if currentIndex is at the last index and navigate to '/Page5'
                if (currentIndex === lastIndex) {
                    // Delay the navigation to ensure it happens after the last image is rendered
                    setTimeout(() => {
                        // Create an object to store the data
                        const imageData = {
                            flag: state?.flag, // Use the passed flag or default to 0
                            files_list: imageUrls.map((url, index) => ({url})),
                        };
                        console.log(imageData.flag)
                        // Convert the object to a JSON string and store it in local storage
                        localStorage.setItem('imageData', JSON.stringify(imageData));
                        navigate('/Page5');
                    }, 5000);
                }
            };
        }
    }, [imageUrls, currentIndex, navigate]);

    // Check if imageUrls is not available or is an empty array
    if (!imageUrls || imageUrls.length === 0) {
        return <div>No images available</div>;
    }

    // Set a fixed height for the images and center them
    const containerStyle = {
        height: '100vh', // 100% of the viewport height
        marginLeft: '12vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const imageContainerStyle = {
        position: 'relative',
    };

    const imageStyle = {
        height: '50vh', // Set your desired height
        // objectFit: 'contain', // Resize without cropping
    };

    const progressStyle = {
        position: 'absolute',
        marginTop: '-80vh',
        width: '80%',
    };

    return (
        <div>
            <div>
                <p style={{fontSize: '15px', marginTop: '70px', marginLeft: '15px'}}>
                    In the next series, we are going to display a series of Biophilic artworks, and then we will ask you
                    to rate how you feel after viewing the pictures.
                </p>
            </div>
            <div style={containerStyle}>
                <img src={imageUrls[currentIndex]} alt={`Image ${currentIndex + 1}`} style={imageStyle}/>
                <Progress percent={progress} status="active" style={progressStyle}/>

                {/* Show the "Next" button when the last image is displayed */}
                {currentIndex === lastIndex && (
                    <Button
                        type="primary"
                        onClick={() => navigate('/Page5')}
                        style={{marginTop: '20px'}}
                    >
                        Next
                    </Button>
                )}
            </div>
        </div>
    );
};

export default ImagePage;
