import React, {useState} from 'react';
import {Table, Radio, Button} from 'antd';
import {useNavigate} from 'react-router-dom';

const Page3 = () => {
    const navigate = useNavigate();
    const columns = [
        {
            title: ' ',
            dataIndex: 'criteria',
            width: '50px',
        },
        {
            title: 'Very Slightly or not at all',
            dataIndex: 'slightly',
            render: (text, record) => (
                <Radio.Group
                    onChange={(e) => handleRadioChange(e, record.criteria, 'slightly', record.key)}
                    value={emotionState[record.key]?.[record.criteria] === 'slightly' ? 1 : null}
                >
                    <Radio value={1}></Radio>
                </Radio.Group>
            ),
            width: '50px',
        },
        {
            title: 'A little',
            dataIndex: 'a little',
            render: (text, record) => (
                <Radio.Group
                    onChange={(e) => handleRadioChange(e, record.criteria, 'a little', record.key)}
                    value={emotionState[record.key]?.[record.criteria] === 'a little' ? 1 : null}
                >
                    <Radio value={1}></Radio>
                </Radio.Group>
            ),
            width: '50px',
        },
        {
            title: 'Moderately',
            dataIndex: 'moderately',
            render: (text, record) => (
                <Radio.Group
                    onChange={(e) => handleRadioChange(e, record.criteria, 'moderately', record.key)}
                    value={emotionState[record.key]?.[record.criteria] === 'moderately' ? 1 : null}
                >
                    <Radio value={1}></Radio>
                </Radio.Group>
            ),
            width: '50px',
        },
        {
            title: 'Quite a bit',
            dataIndex: 'quite a bit',
            render: (text, record) => (
                <Radio.Group
                    onChange={(e) => handleRadioChange(e, record.criteria, 'quite a bit', record.key)}
                    value={emotionState[record.key]?.[record.criteria] === 'quite a bit' ? 1 : null}
                >
                    <Radio value={1}></Radio>
                </Radio.Group>
            ),
            width: '50px',
        },
        {
            title: 'Extremely',
            dataIndex: 'extremely',
            render: (text, record) => (
                <Radio.Group
                    onChange={(e) => handleRadioChange(e, record.criteria, 'extremely', record.key)}
                    value={emotionState[record.key]?.[record.criteria] === 'extremely' ? 1 : null}
                >
                    <Radio value={1}></Radio>
                </Radio.Group>
            ),
            width: '50px',
        },
    ];

    const data = [
        {
            key: '1',
            criteria: 'Relaxed,Calm'
        },
        {
            key: '2',
            criteria: 'Proud,Grand'
        },
        {
            key: '3',
            criteria: 'Nourished, Fullfilled'
        },
        {
            key: '4',
            criteria: 'Attentive, Concentrating'
        },
        {
            key: '5',
            criteria: 'Sad, Downhearted'
        },
        {
            key: '6',
            criteria: 'Afraid, Frightened'
        },
        {
            key: '7',
            criteria: 'Upset, Distressed'
        },
        {
            key: '8',
            criteria: 'Inspired, Amazed'
        },
        {
            key: '9',
            criteria: 'Energised, Excited'
        },
        {
            key: '10',
            criteria: 'Happy, Cheerful'
        },
        {
            key: '11',
            criteria: 'Determined, Confident'
        },
        {
            key: '12',
            criteria: 'Safe, Cosy'
        },
        {
            key: '13',
            criteria: 'Ashamed, Guilty'
        },
        {
            key: '14',
            criteria: 'Shy, Bashful'
        },
        {
            key: '15',
            criteria: 'Hostile, Angry'
        },
    ];

    const initialEmotionState = {
        'Relaxed,Calm': null,
        'Proud,Grand': null,
        'Nourished, Fulfilled': null,
        'Attentive, Concentrating': null,
        'Sad, Downhearted': null,
        'Afraid, Frightened': null,
        'Upset, Distressed': null,
        'Inspired, Amazed': null,
        'Energised, Excited': null,
        'Happy, Cheerful': null,
        'Determined, Confident': null,
        'Safe, Cosy': null,
        'Ashamed, Guilty': null,
        'Shy, Bashful': null,
        'Hostile, Angry': null,
    };

    const [emotionState, setEmotionState] = useState(initialEmotionState);

    const handleRadioChange = (e, criteria, emotion, key) => {
        setEmotionState((prevEmotionState) => ({
            ...prevEmotionState,
            [key]: {
                ...prevEmotionState[key],
                [criteria]: emotion,
            },
        }));
    };
    const handleSubmit = () => {
        const backendEndpoint = 'https://api.vieunite.com/biophilic/emotions';

        const choicesArray = data.map(item => {
            const emotionValue = emotionState[item.key]?.[item.criteria];
            // You can modify this part based on how you want to map the emotion values to numeric choices
            return emotionValue === 'slightly' ? 0 :
                emotionValue === 'a little' ? 1 :
                    emotionValue === 'moderately' ? 2 :
                        emotionValue === 'quite a bit' ? 3 :
                            emotionValue === 'extremely' ? 4 : null;
        });
        // Save choicesArray to local storage
        localStorage.setItem('emotions', JSON.stringify(choicesArray));

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Choices: choicesArray,
            }),
            redirect: 'follow',
        };

        fetch(backendEndpoint, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Data sent successfully:', data);

                // Extract imageUrls from the response data
                const imageUrls = data.files_list.map(item => item);

                // Extract the flag from the response data
                const flag = data.flag;
                console.log(flag)

                // Use the navigate function to go to the ImagePage route and pass the imageUrls as state
                navigate('/ImagePage', {state: {imageUrls,flag}});
            })
            .catch(error => {
                console.error('Error sending data:', error);
            });
    };

    return (
        <div>
            <div style={{ fontSize: '15px', marginTop: '80px', marginLeft: '15px' }}>
                <p>This section will ask for your emotional response, for each emotion below, please indicate to what extent you feel this way right now.</p>
            </div>
            <div>
                <Table columns={columns} dataSource={data} pagination={false} style={{marginTop: '5px'}} tableLayout={"fixed"}/>
            </div>
            <div style={{marginLeft: '900px', marginTop: '40px' }}>
                <Button type="primary" onClick={handleSubmit}>Next</Button>
            </div>
        </div>
    );
};

export default Page3;
