import React, {useEffect, useState} from 'react';
import {Table, Radio, Button, Modal} from 'antd';
import {useLocation} from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import {sleep} from "antd-mobile/es/utils/sleep";

const Page5 = () => {
   // console.log(JSON.parse(localStorage.getItem('imageData'))?.flag)
    const location = useLocation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [formData, setFormData] = useState({});
    const [filesList, setFilesList] = useState([]); // State to store files_list
    const [flag, setFlag] = useState(JSON.parse(localStorage.getItem('imageData'))?.flag);// State to store flag
    const navigate = useNavigate();
    const columns = [
        {
            title: ' ',
            dataIndex: 'criteria',
            width: '150px',
        },
        {
            title: 'Very Slightly or not at all',
            dataIndex: 'slightly',
            render: (text, record) => (
                <Radio.Group
                    onChange={(e) => handleRadioChange(e, record.criteria, 'slightly')}
                    value={emotionState[record.criteria] === 'slightly' ? 1 : null}
                >
                    <Radio value={1}></Radio>
                </Radio.Group>
            ),
            width: '150px',
        },
        {
            title: 'A little',
            dataIndex: 'a little',
            render: (text, record) => (
                <Radio.Group
                    onChange={(e) => handleRadioChange(e, record.criteria, 'a little')}
                    value={emotionState[record.criteria] === 'a little' ? 1 : null}
                >
                    <Radio value={1}></Radio>
                </Radio.Group>
            ),
            width: '150px',
        },
        {
            title: 'Moderately',
            dataIndex: 'moderately',
            render: (text, record) => (
                <Radio.Group
                    onChange={(e) => handleRadioChange(e, record.criteria, 'moderately')}
                    value={emotionState[record.criteria] === 'moderately' ? 1 : null}
                >
                    <Radio value={1}></Radio>
                </Radio.Group>
            ),
            width: '150px',
        },
        {
            title: 'Quite a bit',
            dataIndex: 'quite a bit',
            render: (text, record) => (
                <Radio.Group
                    onChange={(e) => handleRadioChange(e, record.criteria, 'quite a bit')}
                    value={emotionState[record.criteria] === 'quite a bit' ? 1 : null}
                >
                    <Radio value={1}></Radio>
                </Radio.Group>
            ),
            width: '150px',
        },
        {
            title: 'Extremely',
            dataIndex: 'extremely',
            render: (text, record) => (
                <Radio.Group
                    onChange={(e) => handleRadioChange(e, record.criteria, 'extremely')}
                    value={emotionState[record.criteria] === 'extremely' ? 1 : null}
                >
                    <Radio value={1}></Radio>
                </Radio.Group>
            ),
            width: '150px',
        },
    ];

    const data = [
        {
            key: '1',
            criteria: 'Relaxed,Calm'
        },
        {
            key: '2',
            criteria: 'Proud,Grand'
        },
        {
            key: '3',
            criteria: 'Nourished, Fulfilled'
        },
        {
            key: '4',
            criteria: 'Attentive, Concentrating'
        },
        {
            key: '5',
            criteria: 'Sad, Downhearted'
        },
        {
            key: '6',
            criteria: 'Afraid, Frightened'
        },
        {
            key: '7',
            criteria: 'Upset, Distressed'
        },
        {
            key: '8',
            criteria: 'Inspired, Amazed'
        },
        {
            key: '9',
            criteria: 'Energised, Excited'
        },
        {
            key: '10',
            criteria: 'Happy, Cheerful'
        },
        {
            key: '11',
            criteria: 'Determined, Confident'
        },
        {
            key: '12',
            criteria: 'Safe, Cosy'
        },
        {
            key: '13',
            criteria: 'Ashamed, Guilty'
        },
        {
            key: '14',
            criteria: 'Shy, Bashful'
        },
        {
            key: '15',
            criteria: 'Hostile, Angry'
        },
    ];

    const initialEmotionState = {
        'Relaxed,Calm2': null,
        'Proud,Grand2': null,
        'Nourished, Fulfilled2': null,
        'Attentive, Concentrating2': null,
        'Sad, Downhearted2': null,
        'Afraid, Frightened2': null,
        'Upset, Distressed2': null,
        'Inspired, Amazed2': null,
        'Energised, Excited2': null,
        'Happy, Cheerful2': null,
        'Determined, Confident2': null,
        'Safe, Cosy2': null,
        'Ashamed, Guilty2': null,
        'Shy, Bashful2': null,
        'Hostile, Angry2': null,
    };

    const [emotionState, setEmotionState] = useState(initialEmotionState);

    const handleRadioChange = (e, criteria, emotion) => {
        console.log(e, criteria, emotion);
        setEmotionState((emotionState) => ({
            ...emotionState,
            [criteria]: emotion,
        }));
    };

    // 获取之前页面保存的数据(新加)
    useEffect(() => {
        const savedFormData = JSON.parse(localStorage.getItem('formData'));
        const savedEmotionState = JSON.parse(localStorage.getItem('emotions'));
        const savedFilesList = JSON.parse(localStorage.getItem('imageData'))?.files_list;
        const savedFlag = JSON.parse(localStorage.getItem('imageData'))?.flag;

        if (savedFormData) {
            setFormData(savedFormData);
        }

        if (savedEmotionState) {
            setEmotionState(savedEmotionState);
        }

        if (savedFilesList) {
            setFilesList(savedFilesList);
        }
        if (savedFlag) {
            setFlag(savedFlag);
        }
        console.log(savedFlag)
    }, []);
    // Function to handle form submission
    const handleSubmit = () => {
        // Combine all data from localStorage

        const savedFormData = JSON.parse(localStorage.getItem('formData'));
        console.log(savedFormData);
        const savedEmotionState = JSON.parse(localStorage.getItem('emotions'));
        console.log(savedEmotionState);

        // Check if any radio button is not selected
        if (!savedFormData || !savedEmotionState || Object.values(savedEmotionState).some((value) => value === null)) {
            // Show an error message or handle it as needed
            console.error('Please fill out all the required fields.');
            return;
        }
        console.log(emotionState);
        const choicesArray = data.map(item => {
            const emotionValue = emotionState[item.criteria];
            console.log(item)

            // You can modify this part based on how you want to map the emotion values to numeric choices
            return emotionValue === 'slightly' ? 0 :
                emotionValue === 'a little' ? 1 :
                    emotionValue === 'moderately' ? 2 :
                        emotionValue === 'quite a bit' ? 3 :
                            emotionValue === 'extremely' ? 4 : null;
        });
        console.log(choicesArray)
        // Combine all data in the desired format
        const combinedData = {
            consent: savedFormData.consent,
            participant_code: savedFormData.participant_code,
            laptop: savedFormData.laptop === 'Yes' ? 'Yes' : 'No',
            age: parseInt(savedFormData.age, 10) || 0,
            ethnicity: savedFormData.ethnicity,
            Gender: savedFormData.Gender,
            residence: savedFormData.residence,
            spaces: savedFormData.spaces,
            spaces2: savedFormData.spaces2,
            fluency: savedFormData.fluency,
            Relaxed_Calm: savedEmotionState['0'],
            Proud_Grand: savedEmotionState['1'],
            Nourishing_Fullfilled: savedEmotionState['2'],
            Attentive_Concentrating: savedEmotionState['3'],
            Sad_Downhearted: savedEmotionState['4'],
            Afraid_Frightened: savedEmotionState['5'],
            Upset_Distressed: savedEmotionState['6'],
            Inspired_Amazed: savedEmotionState['7'],
            Energised_Excited: savedEmotionState['8'],
            Happy_Cheerful: savedEmotionState['9'],
            Determined_Confident: savedEmotionState['10'],
            Safe_Cosy: savedEmotionState['11'],
            Ashamed_Guilty: savedEmotionState['12'],
            Shy_Bashful: savedEmotionState['13'],
            Hostile_Angry: savedEmotionState['14'],
            flag: flag,
            files_list: filesList,
            Relaxed_Calm2: choicesArray[0],
            Proud_Grand2: choicesArray[1],
            Nourishing_Fullfilled2: choicesArray[2],
            Attentive_Concentrating2: choicesArray[3],
            Sad_Downhearted2: choicesArray[4],
            Afraid_Frightened2: choicesArray[5],
            Upset_Distressed2: choicesArray[6],
            Inspired_Amazed2: choicesArray[7],
            Energised_Excited2: choicesArray[8],
            Happy_Cheerful2: choicesArray[9],
            Determined_Confident2: choicesArray[10],
            Safe_Cosy2: choicesArray[11],
            Ashamed_Guilty2: choicesArray[12],
            Shy_Bashful2: choicesArray[13],
            Hostile_Angry2: choicesArray[14],
        };
        console.log(combinedData);

        // 转换为字符串
        const combinedDataString = JSON.stringify(combinedData);

        // Make a POST request to the API
        fetch('https://api.vieunite.com/biophilic/savedata', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: combinedDataString,
        })
            .then(response => response.json())
            .then(data => {
                // Handle the response from the API if needed
                console.log('Response from server:', data);
                // Show the modal after successful submission
                setIsModalVisible(true);
            })
            .catch(error => {
                // Handle errors if any
                console.error('Error:', error);
            });
        // 使用 navigate 进行跳转
        navigate('/Submit');
    };

    return (
        <div>
            <div style={{ fontSize: '15px', marginTop: '80px', marginLeft: '15px' }}>
                <p>Now that you have viewed the Biophilic artworks, for each emotion below, please indicate to what extent you feel this way right now.</p>
            </div>
            <Table columns={columns} dataSource={data} pagination={false} style={{ marginTop: '5px' }} />
            {/* Thank You message */}
            <div style={{ marginTop: '20px', textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}>
                Thank You for your participation.
            </div>
            {/* Submit button */}
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
                <Button type="primary" onClick={handleSubmit} disabled={Object.values(emotionState).some((value) => value === null)}>
                    Submit
                </Button>
            </div>
        </div>
    );
};

export default Page5;
