import React, {useEffect} from 'react';
import {Col, Divider, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import "./style.css";
import {Text} from "./text";
import ShadowHeader from "../../components/Header/ShadowHeader";
import {NoContactFooter} from "../../components/Footer/PageFooter";
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';


const Primary_Font = {
    fontFamily: "Proxima Nova bold, sans-serif",
}

const TitleFont = {
    fontSize: '42px',
    fontFamily: "Proxima Nova bold, sans-serif",
}

const mapCenter = {
    lat: 52.441596959472406,
    lng: -1.999746487113189,
};

function Contact(props) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyAcXHnSYmUPEY9e-ex-N__YbsiFWiDd1RU',
    });
    return isLoaded? (
            <div>
                <ShadowHeader/>
                <Layout>
                    <Content style={{background: "#D9D9D9"}}>
                        <Row className={'m-top'} justify={"center"} style={{margin: '10rem 0 2rem 0'}} gutter={48}>
                            <Col lg={8} xs={22}>
                                <h2 className="title-app m-font"
                                    style={{textAlign: "left", ...TitleFont}}>
                                    <span style={{fontWeight: '500'}}>Contact</span> Vieunite.
                                </h2>
                                <p style={{...Primary_Font}}>
                                    {Text["contact"]}
                                </p>
                                <p style={{...Primary_Font, color: "black", fontWeight: '800'}}>Vieunite Opening Hours</p>
                                <p style={{...Primary_Font, lineHeight: '0.1rem'}}>{Text['open']}</p>
                            </Col>
                            <Col lg={6} xs={20}>
                                <h2 className="title-app"
                                    style={{textAlign: "center", ...TitleFont}}>
                                    <span style={{fontWeight: '500'}}>Call</span> Us.
                                </h2>
                                <p className={'m-p'}
                                   style={{...Primary_Font, textAlign: "center", maxWidth: '60%', margin: '0 auto'}}>
                                    {Text["call"]}
                                </p>
                                <button type="submit"
                                        className={"pay-button"}
                                        onClick={() => {
                                            window.location.href = 'tel:03455-481311';
                                        }}
                                        style={{
                                            fontFamily: "Proxima Nova, sans-serif",
                                            fontWeight: '600',
                                            borderRadius: "8.69px",
                                            padding: '0.5rem',
                                            width: '50%',
                                            margin: '1rem auto'
                                        }}
                                >
                                    CALL
                                </button>
                            </Col>
                            <div className={'sim-line'}></div>
                            <Divider className={'m-only'} style={{
                                backgroundColor: 'black',
                                width: '50%'
                            }}/>
                            {/* Vertical line */}
                            <Col lg={6} xs={20}>
                                <h2 className="title-app"
                                    style={{textAlign: "center", ...TitleFont}}>
                                    <span style={{fontWeight: '500'}}>Email</span> Us.
                                </h2>
                                <p className={'m-p'}
                                   style={{...Primary_Font, textAlign: "center", maxWidth: '80%', margin: '0 auto'}}>
                                    {Text["email"].map((txt) => <p style={{...Primary_Font}}>{txt}</p>)}
                                </p>
                                <button type="submit"
                                        className={"pay-button"}
                                        onClick={() => {
                                            window.location.href = 'mailto:info@vieunite.com';
                                        }}
                                        style={{
                                            fontFamily: "Proxima Nova, sans-serif",
                                            fontWeight: '600',
                                            borderRadius: "8.69px",
                                            padding: '0.5rem',
                                            width: '50%',
                                            margin: '1rem auto',
                                        }}
                                >
                                    EMAIL
                                </button>
                            </Col>
                            <Divider className={'m-only'} style={{
                                backgroundColor: 'black',
                                width: '50%'
                            }}/>
                            {/* Vertical line */}
                            <Col lg={6} xs={20} className={'m-only'}>
                                <h2 className="title-app"
                                    style={{textAlign: "center", ...TitleFont}}>
                                    <span style={{fontWeight: '500'}}>Our</span> Address.
                                </h2>
                                <p className={'m-only'}
                                   style={{...Primary_Font, textAlign: "center", maxWidth: '80%', margin: '0 auto'}}>
                                    {Text["office"].map((txt) => <p
                                        style={{...Primary_Font, lineHeight: '0.9rem'}}>{txt}</p>)}
                                </p>
                            </Col>
                        </Row>

                        <Row className={'m-map-row'} justify={"center"} style={{margin: '6rem 0'}}>
                            <Col lg={8} xs={0} style={{
                                backgroundColor: 'white',
                                borderTopLeftRadius: '20px',
                                borderBottomLeftRadius: '20px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: "center",
                                alignItems: 'center',
                                textAlign: 'left',
                                marginLeft: '1.8%',
                            }}>
                                <h2 className="title-app m-none"
                                    style={{textAlign: "center", ...TitleFont}}>
                                    <span style={{fontWeight: '500'}}>Our</span> Office.
                                </h2>
                                <p className={'m-none'}
                                   style={{...Primary_Font, textAlign: "center", maxWidth: '80%', margin: '0 auto'}}>
                                    {Text["office"].map((txt) => <p
                                        style={{...Primary_Font, lineHeight: '0.9rem'}}>{txt}</p>)}
                                </p>
                            </Col>
                            <Col lg={12} xs={0} style={{
                                height: '60vh',
                                width: '100%',
                            }}>
                                <div className={'google-map'} style={{ height: '100vh', width: '100%' }}>
                                    <GoogleMap
                                        style={{
                                            borderTopRightRadius: '20px',
                                            borderBottomRightRadius: '20px',
                                        }}
                                        mapContainerStyle={{ height: '60vh', width: '100%',borderTopRightRadius: '20px',
                                            borderBottomRightRadius: '20px', }}
                                        // bootstrapURLKeys={{key: "AIzaSyAcXHnSYmUPEY9e-ex-N__YbsiFWiDd1RU"}}
                                        center={mapCenter}
                                        zoom={16}
                                    >
                                        <Marker position={mapCenter}/>
                                    </GoogleMap>
                                </div>
                            </Col>
                        </Row>
                    </Content>
                    <NoContactFooter/>
                </Layout>
            </div>
    ):<></>
}

// function Marker() {
//     return (
//         <>
//             <div style={{
//                 position: 'relative',
//                 width: '30px',
//                 height: '45px',
//                 background: 'transparent',
//             }}>
//                 <MarkerIcon></MarkerIcon>
//                 <span style={{
//                     position: 'absolute',
//                     top: '30px',
//                     left: '50%',
//                     transform: 'translate(-50%)',
//                     fontSize: '14px',
//                     fontWeight: 'bold',
//                     color: 'red',
//                     textAlign: 'center',
//                     maxWidth: '120px',
//                     whiteSpace: 'nowrap',
//                     overflow: 'hidden',
//                     textOverflow: 'ellipsis'
//                 }}>Vieunite</span>
//             </div>
//         </>
//     );
// }


export default Contact;
