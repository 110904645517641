import React, {useEffect, useState} from 'react';
import './style.scss';
import {Button, Col, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import ProPortalHeader from "../../../componets/ProPortalHeader";
import ProfileRow from "../../../componets/ProfileRow";
import Navigator from "../../../componets/Navigator";
import {get_api, post_api, post_api_upload} from "../../../serverTemp";
import Camera from './imgs/camera.svg';
import SparkMD5 from 'spark-md5';
import backButton from "../imgs/Back button.svg";
import {useNavigate} from "react-router-dom";


const MainContent = ({onUserUpdate}) => {
    const [user, setUser] = useState({});
    const [newData, setNewData] = useState({
        id: localStorage.getItem('user_id'),
        avatar: '',
        email: '',
        nickname: '',
    });
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        get_api('/user/detail').then(res => {
            console.log(res)
            setUser(res);
            setNewData({
                ...newData,
                avatar: res.avatar,
                email: res.email,
                nickname: res.nickname
            });
        });
    }, []);

    const calculateFileMD5 = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function (e) {
                const data = e.target.result;
                const md5 = SparkMD5.ArrayBuffer.hash(data);
                resolve(md5);
            };
            reader.onerror = function (error) {
                reject(error);
            };
            reader.readAsArrayBuffer(file);
        });
    };

    const handleFileChange = async (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const fileMD5 = await calculateFileMD5(file);
            post_api_upload(file.name, fileMD5, file)
                .then((url) => {
                    console.log("Response URL:", url);
                    setSelectedImage(URL.createObjectURL(file));
                    setNewData({...newData, avatar: url});
                })
        }
    };

    const triggerFileInput = () => {
        document.getElementById('fileInput').click();
    };

    const getSubscriptionType = (subscription) => {
        const types = ["Standard", "Advanced", "Premium"];
        return types[subscription] || "Unknown";
    };

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setNewData(prevData => ({
            ...prevData,
            [name]: value
        }));
        console.log('Updated newData:', newData);
    };

    const saveChanges = async () => {
        console.log('Saving new data:', newData);
        post_api('/user/update', newData)
            .then(res => {
                console.log('Update response:', res);
                // 重新获取用户数据
                get_api('/user/detail').then(res => {
                    console.log('New user data:', res);
                    setUser(res);
                    onUserUpdate(res); // 调用父组件的回调函数
                });
            })
            .catch(error => {
                console.error('Error while updating:', error);
            });
    };

    return (
        <Row gutter={16}>
            <Col span={12}>
                <p className='PortalAccountDetails-title'>Change your details</p>
                <div>
                    <div className="MD-form-group">
                        <label className='MD-form-group-label'>Account name</label><br/>
                        <input className='MD-form-group-input'
                               type="text"
                               name={'nickname'}
                               value={newData.nickname}
                               onChange={handleInputChange}
                        />
                    </div>
                    <div className="MD-form-group">
                        <label className='MD-form-group-label'>Email address</label><br/>
                        <input className='MD-form-group-input' placeholder={user.email} disabled></input>
                    </div>
                    <div className="MD-form-group">
                        <label className='MD-form-group-label'>Subscription type</label><br/>
                        <input className='MD-form-group-select' placeholder={getSubscriptionType(user.subscription)}
                               disabled></input>
                    </div>
                </div>
                <p className='PortalAccountDetails-content'>
                    To learn more about our subscription plans or upgrade your current subscription,<br/>
                    please visit our <a href="./MyAccount" className="subscriptionLink">Subscription page</a> for
                    detailed information.
                </p>
            </Col>
            <Col span={12}>
                <div className="profile-picture">
                    <img src={selectedImage || Camera} alt="Profile"
                         className={`Camera-icon ${!selectedImage ? "default-icon" : ""}`}/>
                </div>
                <Button className='profile-picture-button' onClick={triggerFileInput}>
                    Update profile picture
                </Button>
                <input type="file" id="fileInput" style={{display: 'none'}} onChange={handleFileChange}/>
            </Col>
            <Button className="MD-Save-button" onClick={saveChanges}>Save changes</Button>
        </Row>
    );
};

const ChangeAccountDetails = () => {
    const [user, setUser] = useState({});

    const handleUserUpdate = (updatedUser) => {
        setUser(updatedUser);
    };

    useEffect(() => {
        get_api('/user/detail').then(res => {
            setUser(res);
        });
    }, []);
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1); // 使用navigate函数导航回上一页
    };

    return (
        <Layout>
            <Content>
                <ProPortalHeader/>
                <ProfileRow/>
                <Row justify={"center"}>
                    <Layout>
                        <Navigator/>
                        <Layout>
                            <div className='ProAccountDetails'>
                                {/*<p className='ProAccountDetails-title'>Hello {user?.nickname}</p>*/}
                                <div className='header3'>
                                    <img src={backButton} alt="BackButton" className='BackButtonIcon8'
                                         style={{marginRight: '0.5rem', cursor: "pointer"}} onClick={handleBackClick}/>
                                    <p className='ProAccountDetails-title'>Hello {user?.nickname}</p>
                                </div>
                                <MainContent onUserUpdate={handleUserUpdate}/>
                            </div>
                        </Layout>
                    </Layout>
                </Row>
            </Content>
        </Layout>
    );
}

export default ChangeAccountDetails;
