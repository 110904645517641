import a15 from "../../img/Artists/16 - Caitlin Flood-Molyneux.webp";
import a1 from "../../img/Artists/1 - Jonathan Armour.webp";
import a6 from "../../img/Artists/BarbaraSafrandeNiverville/Autumn Equinox_square.webp";
import a11 from "../../img/Artists/11 - Richard Battye.webp";
import a7 from "../../img/Artists/Christian Redermaye.webp";
import a3 from "../../img/Artists/3 - Lucy Nelson.webp";
import a14 from "../../img/Artists/15 - Rory Watson.webp";
import a16 from "../../img/Artists/14 - Ant Webb.webp";
import a4 from "../../img/Artists/4 - Jo Vincent.webp";
import a12 from "../../img/Artists/12 - Guille Giagante.webp";
import a5 from "../../img/Artists/5 - Jenette Coldrick.webp";
import a2 from "../../img/Artists/2 - Ke Yang.webp";
import a10 from "../../img/Artists/10 - David Bickley.webp";
import a8 from "../../img/Artists/8 - Doris Luming.webp";
import a9 from "../../img/Artists/9 - Linwang Wang.webp";
import a17 from "../../img/Artists/Angelique Cover Image.webp";
import a18 from "../../img/Artists/18 - Ethan Platt.webp";
import a19 from "../../img/Artists/17 - Matthew Stickland.webp";
import a20 from "../../img/Artists/19 - Alexander Rhys.webp";

export const ArtistDataBase = [
    [
        {'name': 'Alexander Rhys', 'img': a20, 'path': '/alexanderrhys'},
        {'name': 'Ethan Platt', 'img': a18, 'path': '/ethanplatt'},
        {'name': 'Matthew Stickland', 'img': a19, 'path': '/matthewstickland'},
        {'name': 'Angelique Nagovskaya', 'img': a17, 'path': '/angeliquenagovskaya'},
        {'name': 'Caitlin Flood-Molyneux', 'img': a15, 'path': '/caitlinflood'},
        {'name': 'Jonathan Armour', 'img': a1, 'path': '/jonathanarmour'},
        {'name': 'Barbara Safran de Niverville', 'img': a6, 'path': '/barbara'},
        {'name': 'Richard Battye', 'img': a11, 'path': '/richardbattye'},
    ],
    [
        {'name': 'Christian Redermayer', 'img': a7, 'path': '/christianredermayer'},
        {'name': 'Lucy Nelson', 'img': a3, 'path': '/lucynelson'},
        {'name': 'Rory Watson', 'img': a14, 'path': '/rorywatson'},
        {'name': 'Anthony Webb', img: a16, path: '/anthonywebb'},
        {'name': 'Jo Vincent', 'img': a4, 'path': '/jovincent'},
        {'name': 'Guille Giagante', 'img': a12, 'path': '/guillegiagante'},
        {'name': 'Jenette Coldrick', 'img': a5, 'path': '/jenettecoldrick'},
        {'name': 'Ke Yang', 'img': a2, 'path': '/keyang'},
        {'name': 'David Bickley', 'img': a10, 'path': '/davidbickley'},
        {'name': 'Doris Luming', 'img': a8, 'path': '/dorisluming'},
        {'name': 'Linwang Wang', 'img': a9, 'path': '/linwangwang'},
    ],
]
