import React, {useEffect} from 'react';
import {Form, Input, Button, message} from 'antd';
import './style.scss'
import {Link, useLocation} from "react-router-dom";
import ProLogo from './imgs/ProLogo.svg';
import {post_api} from "../../portal/serverTemp";

const ProResetPassword = () => {
    const location = useLocation();
    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        const queryParams = new URLSearchParams(location.search);
        const secret = queryParams.get('secret');
        console.log('The secret is:', secret);
        post_api('/user/change/password',
            {secret: secret, password: values.password})
            .then((res) => {
                if (res && res.id) {
                    window.location.href = '/business/ProPasswordSaved'
                }
                // message.success('Your password has been changed!')
            }).catch(error => console.log('error', error))
    };

    return (
        <div style={{marginTop: '5vw'}}>
            <div className="ProLogo" style={{marginBottom: '100px'}}>
                <img src={ProLogo} alt="ProLogo" className='ProLogo'/>
            </div>
            <div className="Title" style={{marginTop: '70px'}}>
                Reset your password
            </div>
            {/*<p className="ProEmail-text-check">john.doe@gmail.com</p>*/}
            <Form
                initialValues={{remember: true}}
                onFinish={onFinish}
                colon={false}
                // layout="horizontal"
                className={'reset-ps-sp-div-form'}
            >
                <Form.Item
                    name="password"
                    rules={[
                        {required: true, message: 'Please input your password!'},
                        {
                            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/,
                            message: 'Password must be at least 10 characters and include @$!%*?&, lowercase, uppercase, and numbers.'
                        }
                    ]}
                    labelCol={{span: 20}}
                    wrapperCol={{span: 20}}
                >
                    <Input.Password className={'ProResetPassword-input-Email-form'}
                                    style={{width: '30vw', height: '6vh', backgroundColor: '#D9D9D9'}}
                                    placeholder="New password"/>
                </Form.Item>
                <div className="ProReset-center-button" style={{marginBottom: '200px'}}>
                    <Button type="primary" htmlType="submit" className="ProReset-custom-Reset-button"
                            style={{width: '30vw', height: '6vh'}}>
                        Continue
                    </Button>
                </div>
            </Form>

        </div>
    );
};

export default ProResetPassword;
