import React from 'react';
import "./VerticalCarousel.css";
import vd from '../../img/Artists/jonathanarmour/1 - Header Image or Video/Jonathan Armour - Desktop.mp4'
import img_1 from "../../img/Textura/1 - Gallery/3 - Black.webp";
import img_2 from "../../img/Textura/1 - Gallery/5 - Birch.webp";
import img_3 from "../../img/Textura/1 - Gallery/1 - Pine.webp";
import img_4 from "../../img/Textura/1 - Gallery/6 - Walnut.webp";
import img_9 from "../../img/Textura/1 - Gallery/8 - Vieunite_Mockup_Be Noticed Design - 01.webp";
import img_6 from "../../img/Textura/1 - Gallery/2 - Vieunite 2023 03.webp";
import img_7 from "../../img/Textura/1 - Gallery/7 - Vieunite 2023 02.webp";
import img_8 from "../../img/Textura/1 - Gallery/4 - alexandra-gorn-W5dsm9n6e3g-unsplash.webp";
import img_5 from "../../img/Textura/1 - Gallery/9 - Frames.webp";
import vd_1 from "../../img/Textura/1 - Gallery/V FINAL 1080p - Single Logo End.mp4";
import tb_1 from "../../img/Textura/1 - Gallery/20230822-145615.webp";
import Carousel from "./Carousel";

class VerticalCarousel extends React.Component {
    render() {
        const data = [
            {
                video: vd_1,
                thumbnail:tb_1,
            },
            {
                image: img_1,
            }, {
                image: img_2,
            }, {
                image: img_3,
            }, {
                image: img_4,
            },   {
                image: img_5,
            }, {
                image: img_6,
            }, {
                image: img_7,
            }, {
                image: img_8,
            }, {
                image: img_9,
            },];
        const slideNumberStyle = {
            fontSize: '20px', fontWeight: 'bold',
        }

        return (<div className="App">
            <div style={{textAlign: "center"}}>
                <div style={{
                    padding: "0 20px"
                }}>
                    <Carousel
                        data={data}
                        time={3000}
                        width="550px"
                        height="550px"
                        radius="10px"
                        slideNumber={false}
                        slideNumberStyle={slideNumberStyle}
                        captionPosition="bottom"
                        automatic={false}
                        dots={false}
                        pauseIconColor="white"
                        pauseIconSize="40px"
                        slideBackgroundColor="darkgrey"
                        slideImageFit="cover"
                        thumbnails={true}
                        thumbnailWidth="100px"
                        thumbnailHeight={'100px'}
                        style={{
                            textAlign: "center",
                            maxWidth: "850px",
                            maxHeight: "500px",
                            margin: "50px auto",
                            backgroundColor: '#f4f4f4'
                        }}
                    />
                </div>
            </div>
        </div>);
    }
}

export default VerticalCarousel;
