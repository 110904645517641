import React, {useEffect, useState} from 'react';
import './style.scss';
import {Button, Card, Col, Form, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import ProPortalHeader from "../../../componets/ProPortalHeader";
import ProfileRow from "../../../componets/ProfileRow";
import Navigator from "../../../componets/Navigator";
import { Input } from 'antd';
import {post_api} from "../../../serverTemp";

const PasswordChangeForm = () => {
    const [form] = Form.useForm(); // 使用Form.useForm创建表单实例
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const handleSubmit = (values) => {
        console.log('Old Password:', oldPassword);
        console.log('New Password:', newPassword);
        console.log('Confirm Password:', confirmPassword);
        console.log('Received values of form: ', values);
        console.log('Form Values:', values);
        const data = {
            "password": values.newPassword
        }
        post_api('/user/change/password', data)
            .then(res => {
                console.log(res);
            })
    };

    return (
        <Card title="Password change" className="ProportalPasswordTitle custom-card">
            <Form
                form={form} // 将form实例传递给Form组件
                onFinish={handleSubmit}
                layout="vertical"
            >
                <Form.Item
                    label="Old Password"
                    required
                    tooltip="This is a required field"
                >
                    <Input.Password value={oldPassword} onChange={(e) => setOldPassword(e.target.value)}/>
                </Form.Item>
                <Form.Item
                    label="New Password"
                    required
                    tooltip="This is a required field"
                    name="newPassword"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your new password!',
                        },
                        {
                            min: 8,
                            message: 'Password must be at least 8 characters!',
                        },
                        {
                            pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                            message: 'Password must include letters, numbers, and special characters!',
                        }
                    ]}
                >
                    <Input.Password value={newPassword} onChange={(e) => setNewPassword(e.target.value)}/>
                </Form.Item>
                <Form.Item
                    label="Confirm Password"
                    required
                    tooltip="This is a required field"
                    name="confirmPassword"
                    dependencies={['newPassword']} // 依赖newPassword字段，确保每次newPassword字段变化时重新验证
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password onChange={(e) => setConfirmPassword(e.target.value)}/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="ChangePasswordSubmit">
                        Save changes
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};
const MainContent = () => (
    <Row gutter={16} className='PortalchangePassword'>
        <Col span={24}>
            <PasswordChangeForm/>
        </Col>
    </Row>
);

const ChangePasswordPro = () => {
    return (
        <Layout>
            <Content>
                <ProPortalHeader/>
                <ProfileRow/>
                <Row justify={"center"}>
                    <Layout>
                        <Navigator/>
                        <Layout>
                            <div className='ProChangePassword'>
                                <p className='ProChangePassword-title'>Hello Vieunite Ltd</p>
                                <MainContent/>
                            </div>
                        </Layout>
                    </Layout>
                </Row>
            </Content>
        </Layout>
    )
}

export default ChangePasswordPro;