import React, {useCallback, useEffect, useState} from 'react';
import {Col, Layout, Row} from 'antd';
import {Outlet, useLocation} from "react-router-dom";
import ProHeader from "../components/ProHeader";
import ProFooter from "../components/ProFooter";
import mobileImg from './Mobile Holding Image.webp'
import useWindowResize from "../../apps/Hooks/useWindowResize";

const {Content} = Layout;

function ProLayout() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    console.log(userAgent)
    return (
        // /Android|iPad|iPhone|iPod/.test(userAgent) ?
        //     <img src={mobileImg}
        //          alt={'thanks!'}
        //          style={{width: '100vw', height: '100vh'}}
        //     /> :
            <div>
                <ProHeader/>
                <Layout className="layout" style={{width: '100%'}}>
                    <Content>
                        <Outlet/>
                    </Content>
                </Layout>
                <ProFooter/>
            </div>
    );
}

export default ProLayout;