import React, {useCallback, useEffect, useState} from 'react';
import {Col, Layout, Row} from 'antd';
import './App.css';
import './BasicLayout.css'
import {Outlet, useLocation} from "react-router-dom";
import PageFooter, {BottomRow, PortalFooter} from "./components/Footer/PageFooter";
import ShadowHeader from "./components/Header/ShadowHeader";
import PageViewTracking from "./components/GoogleAnalytics";
import {TitleFont} from "./settings";
import SaleModal from "./components/SaleModal";


const {Content} = Layout;

function BasicLayout() {
    const {pathname, search} = useLocation();
    const analytics = useCallback(() => {
        PageViewTracking({path: pathname, search: search, title: pathname.split("/")[1]});
    }, [pathname, search]);

    useEffect(() => {
        analytics();
    }, [analytics]);
    return (
        <div>
            <Layout className="layout" style={{width: '100%'}}>
                <ShadowHeader/>
                <Content>
                    <Outlet/>
                </Content>
                <PageFooter/>
            </Layout>
        </div>
    );
}

export default BasicLayout;