import React from 'react';
import './ProductDetail.css';
import "./Textura.css";
import {
    Col,
    Row,
    Layout,
    Collapse,
    Image, Space,
} from 'antd';
import {Content} from "antd/es/layout/layout";
import {HOME_TEXT, PRODUCT_TEXT} from "../../text"
import {ProductDetailPageMeta} from "../../components/PageMeta/ProductDetailPageMeta";
import Transforming_Img from "../../img/Textura/2 - Discover the World/Vieunite_Mockup_03_2.webp";
import {TEXTURA_TEXT} from "../../text";
import LifeLike_Img from "../../img/Textura/3 - Textura/Pine-desktop.webp";
import mLifeLike_Img from "../../img/Textura/3 - Textura/Pine-mobile-tablet.webp";
import MadeForYou_Img from "../../img/Textura/4 - Made For You/Textura - Frames - High Res - Cropped.webp";
import Textura_App_Img from "../../img/Textura/5 - Textura App/vieunite-app-art.webp";
import {PlusOutlined, StarFilled} from "@ant-design/icons";
import VerticalCarousel from "../../components/Carousel/VerticalCarousel";
import {Reviews, ReviewCard} from "./Reviews";
import SaleModal from "../../components/SaleModal";

// Fake cards for testing
// Payment succeeds
// 4242 4242 4242 4242
// Payment requires authentication
// 4000 0025 0000 3155
// Payment is declined
// 4000 0000 0000 9995
const {Panel} = Collapse;

const PRICE = 599;

const COLOR_CODE = {
    'a': 'Black',
    'b': 'Birch',
    'c': 'Pine',
    'd': 'Walnut',
}

const PrimaryFont = {
    fontFamily: "Proxima Nova bold, sans-serif",
}

const MWidth = {
    span: 20,
}

const TitleFont = {
    fontSize: '38px',
    fontFamily: "Proxima Nova bold, sans-serif",
}

function scrollDown(id) {
    window.location = id;
    window.scrollBy(0, -100);
}

class ProductDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            color: 'a', //a:black,b:birch,c:pine,d:walnut
        }
    }

    checkOut = () => {
        const color = this.state.color;
        //a:black,b:birch,c:pine,d:walnut
        if (color === 'a') {
            window.open("https://buy.stripe.com/fZe7sO2SufPpejK28e", '_blank');
        } else if (color === 'b') {
            // window.open("https://buy.stripe.com/14kdRc3WyeLl7VmaEM", '_blank');
            window.alert('Sorry, birch canvas is out of stock.')
        } else if (color === 'c') {
            window.open("https://buy.stripe.com/fZecN8gJkcDda3u5kt", '_blank');
        } else if (color === 'd') {
            window.open("https://buy.stripe.com/eVa28uct48mX5NefZ5", '_blank');
        } else {
        }
    }

    render() {
        return (
            <div>
                <ProductDetailPageMeta></ProductDetailPageMeta>

                <Layout style={{marginTop: '35px'}}>
                    <Content>
                        <Row justify={"center"}
                             style={{
                                 backgroundColor: '#f4f4f4',
                                 columnGap: '4rem'
                             }}
                        >
                            <Col lg={0} xs={20} style={{marginTop: '6rem'}}>
                                <Row>
                                    <p className={"product-title"}
                                       style={{
                                           fontFamily: "Proxima Nova, sans-serif",
                                           fontSize: '32px',
                                           ...TitleFont
                                       }}>
                                        Textura Digital Canvas
                                    </p>
                                </Row>
                                <Row style={{alignItems: "center",}}>
                                    <p style={{
                                        marginTop: "-30px",
                                        fontFamily: "Proxima Nova, sans-serif",
                                        fontWeight: '600',
                                        fontSize: '1.2rem'
                                    }}>
                                        Bring art into your home through the power of technology.
                                    </p>
                                </Row>
                                <Row style={{alignItems: "center", gap: '0.5vw'}}>
                                    <Col lg={8} style={{display: "contents", gap: '0.8em', flexWrap: "wrap"}}>
                                        {[...Array(5)].map(() => <StarFilled style={{fontSize: '1.5em'}}/>)}
                                    </Col>
                                    <Col style={{
                                        fontStyle: 'italic',
                                        fontFamily: "Proxima Nova, sans-serif",
                                        fontWeight: '600',
                                        fontSize: '1rem'
                                    }}
                                    >
                                        <div onClick={() => scrollDown("#REVIEWS")} style={{cursor: "pointer"}}>Read
                                            Reviews
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={10} lg={10} xs={20} className={'carousel-dt'}>
                                <VerticalCarousel>
                                </VerticalCarousel>
                            </Col>
                            <Col span={10} lg={10} xs={{...MWidth}} style={{paddingTop: '4rem'}}>
                                <Row className={'m-none'}>
                                    <p className={"product-title"}
                                       style={{
                                           fontFamily: "Proxima Nova, sans-serif",
                                           fontSize: '32px',
                                           ...TitleFont
                                       }}>
                                        Textura Digital Canvas
                                    </p>
                                </Row>
                                <Row className={'m-none'} style={{alignItems: "center",}}>
                                    <p style={{
                                        marginTop: "-40px",
                                        fontFamily: "Proxima Nova, sans-serif",
                                        fontWeight: '600',
                                        fontSize: '1.2rem'
                                    }}>
                                        Bring art into your home through the power of technology.
                                    </p>
                                </Row>
                                <Row className={'m-none'}
                                     style={{alignItems: "center", gap: '0.5vw', marginTop: "-11px",}}>
                                    <Col lg={8} style={{display: "contents", gap: '0.8em', flexWrap: "wrap"}}>
                                        {[...Array(5)].map(() => <StarFilled style={{fontSize: '1.8em'}}/>)}
                                    </Col>
                                    <Col style={{
                                        fontStyle: 'italic',
                                        fontFamily: "Proxima Nova, sans-serif",
                                        fontWeight: '600',
                                        fontSize: '1.3rem'
                                    }}>
                                        <div onClick={() => scrollDown("#REVIEWS")} style={{cursor: "pointer"}}>Read
                                            Reviews
                                        </div>
                                    </Col>
                                </Row>
                                <Row className={'m-none sales-code'} justify={"end"}
                                     style={{margin: '5% 0 -3% 0'}}
                                >
                                    <Col lg={14} sm={0} style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}>
                                        <p className={'copy-code'}
                                           style={{
                                               backgroundColor: "black",
                                               color: "white",
                                               padding: '0.5rem 1rem',
                                               fontWeight: "600",
                                               border: '0.1rem dashed white',
                                           }}>
                                            Big summer sale
                                            {/*<span*/}
                                            {/*    style={{*/}
                                            {/*        backgroundColor: "#E1306C",*/}
                                            {/*        color: "black",*/}
                                            {/*        padding: '0.1rem 0.4rem',*/}
                                            {/*        borderRadius: '0.1rem',*/}
                                            {/*        cursor: "pointer"*/}
                                            {/*    }}*/}
                                            {/*    onClick={_ => window.open(*/}
                                            {/*        'https://www.instagram.com/vieunite/?hl=en'*/}
                                            {/*    )}*/}
                                            {/*>*/}
                                            {/*    Instagram*/}
                                            {/*</span>*/}
                                            {/*&nbsp;*/}
                                            &nbsp;(offer valid until 31st August)
                                        </p>
                                    </Col>
                                </Row>
                                <Row className={'m-margin-top'} style={{
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    // marginTop: '4em',
                                    marginTop: '0',
                                    gap: '0.5vw'
                                }}>
                                    <Col lg={8} style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: '1em',
                                        // flexWrap: "wrap"
                                    }}>
                                        <button
                                            className={this.state.color === 'a' ?
                                                'color-btn black-btn color-btn-active' : 'color-btn black-btn'}
                                            value="a"
                                            style={{
                                                backgroundColor: "black",
                                            }}
                                            onClick={(e) => {
                                                document.getElementById('thumbnail-1').click();
                                                this.setState({color: e.target.value});
                                            }}>
                                        </button>
                                        {/*<button className={this.state.color === 'b' ?*/}
                                        {/*    'color-btn birch-btn color-btn-active' : 'color-btn birch-btn'}*/}
                                        {/*        value="b"*/}
                                        {/*        style={{*/}
                                        {/*            backgroundColor: "#DFD7C8",*/}
                                        {/*        }}*/}
                                        {/*        onClick={(e) => {*/}
                                        {/*            document.getElementById('thumbnail-2').click();*/}
                                        {/*            this.setState({color: e.target.value});*/}
                                        {/*        }}>*/}
                                        {/*</button>*/}
                                        <button
                                            className={this.state.color === 'd' ?
                                                'color-btn walnut-btn color-btn-active' : 'color-btn walnut-btn'}

                                            value="d"
                                            style={{
                                                backgroundColor: "#773f1a",
                                            }}
                                            onClick={(e) => {
                                                document.getElementById('thumbnail-4').click();
                                                this.setState({color: e.target.value});
                                            }}>
                                        </button>
                                        <button className={this.state.color === 'c' ?
                                            'color-btn pine-btn color-btn-active' : 'color-btn pine-btn'}
                                                value="c"
                                                style={{
                                                    backgroundColor: "#eed5ae",
                                                }}
                                                onClick={(e) => {
                                                    document.getElementById('thumbnail-3').click();
                                                    this.setState({color: e.target.value});
                                                }}>
                                        </button>
                                    </Col>

                                    <Col style={{margin: "0 0 0 25%"}}>
                                            <span className={'colour-txt'} style={{
                                                ...PrimaryFont,
                                                fontSize: '0.9rem',
                                            }}>
                                                {COLOR_CODE[this.state.color]}
                                            </span>
                                    </Col>

                                    <Col style={{display: "flex", justifyContent: 'flex-end'}}>
                                        <p style={{
                                            color: 'black',
                                            ...PrimaryFont,
                                            fontSize: '1.2rem',
                                            paddingTop: '1.4rem',

                                        }}>
                                            <span
                                                style={{
                                                    fontSize: '1.8rem',
                                                    color: "#0EAFA5",
                                                    fontWeight: "900",
                                                }}>SAVE £100
                                            </span>&nbsp;&nbsp;
                                            <span
                                                style={{fontWeight: "bold", fontSize: '1.6rem',}}
                                            >
                                                £499
                                            </span>&nbsp;&nbsp;
                                            <span style={{
                                                textDecoration: "line-through"
                                                // fontWeight:"bold"
                                            }}>
                                                £{PRICE}
                                            </span>
                                        </p>
                                    </Col>
                                </Row>
                                <Row className={'m-only-sale sales-code'} justify={"center"}
                                     style={{display: "flex", justifyContent: 'flex-start'}}
                                >
                                    <Col lg={8} sm={0} style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}>
                                        {/*<p*/}
                                        {/*    className={'copy-code'}*/}
                                        {/*    style={{*/}
                                        {/*        backgroundColor: "black",*/}
                                        {/*        color: "white",*/}
                                        {/*        padding: '0.5rem 1rem',*/}
                                        {/*        fontWeight: "600",*/}
                                        {/*        border: '0.1rem dashed white',*/}
                                        {/*    }}>*/}
                                        {/*    Follow us on &nbsp;*/}
                                        {/*    <span*/}
                                        {/*        style={{*/}
                                        {/*            backgroundColor: "#E1306C",*/}
                                        {/*            color: "black",*/}
                                        {/*            padding: '0.1rem 0.4rem',*/}
                                        {/*            borderRadius: '0.1rem',*/}
                                        {/*            cursor: 'pointer'*/}
                                        {/*        }}*/}
                                        {/*        onClick={_ => window.open('https://www.instagram.com/vieunite/?hl=en')}*/}
                                        {/*    >*/}
                                        {/*        Instagram*/}
                                        {/*    </span>*/}
                                        {/*    &nbsp;*/}
                                        {/*    for an exclusive £50 voucher*/}
                                        {/*</p>*/}
                                    </Col>
                                </Row>
                                <button type="submit"
                                        className={"pay-button"}
                                        onClick={this.checkOut}
                                        style={{
                                            marginTop: '1em',
                                            fontFamily: "Proxima Nova, sans-serif",
                                            fontWeight: '800',
                                            borderRadius: "5px",
                                            padding: '0.5em',
                                        }}
                                >
                                    ADD TO CART
                                </button>
                                <p style={{
                                    marginTop: '1em', fontFamily: "Proxima Nova, sans-serif",
                                    fontWeight: '800', color: "#0eafa5", fontSize: '1rem'
                                }}>{TEXTURA_TEXT['Delivery']}</p>
                                <p style={{
                                    marginTop: '1em', fontFamily: "Proxima Nova, sans-serif",
                                    fontWeight: '200', color: "grey", fontSize: '0.75rem'
                                }}>{TEXTURA_TEXT['Warranty']}</p>
                            </Col>
                        </Row>

                        <Row className={'m-margin-top'} justify={"center"}
                             style={{
                                 backgroundColor: '#f4f4f4',
                                 paddingTop: '8em',
                             }}
                        >
                            <Col lg={20} xs={20}>
                                <div className="video-container"
                                     style={{}}>
                                    <Image
                                        height={'100%'}
                                        width={'100%'}
                                        src={Transforming_Img}
                                        preview={false}
                                        style={{
                                            borderRadius: '15px',
                                            objectFit: 'cover',
                                            objectPosition: 'top',
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row justify={"center"}
                             style={{
                                 backgroundColor: '#f4f4f4',
                                 paddingTop: '4em',
                                 paddingBottom: '4em'
                             }}
                        >
                            <Col lg={10} xs={20} style={{
                                alignItems: "center"
                            }}>
                                <div className="text-container m-title-small"
                                     style={{}}>
                                    <p style={{
                                        ...TitleFont, fontWeight: 700,
                                    }}>
                                        Transforming the World Around You.
                                    </p>
                                </div>
                            </Col>
                            <Col lg={10} xs={20}>
                                <div className="text-container"
                                     style={{}}>
                                    <p style={{fontFamily: "Proxima Nova, sans-serif", fontSize: '22px'}}>
                                        {TEXTURA_TEXT["Transforming the World Around You"]}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                        {/*Left-Img-Right-Text Section*/}
                        <Row justify={"space-evenly"}
                             style={{
                                 backgroundColor: 'black',
                                 paddingBottom: '2rem',
                             }}
                             className={"img-text-zone"}
                        >
                            <Col span={12} lg={10} xs={0}>
                                <div className="video-container"
                                     style={{marginLeft: '-20%', marginBottom: '-4.4%'}}>
                                    <Image
                                        src={LifeLike_Img}
                                        preview={false}
                                    />
                                </div>
                            </Col>
                            <Col span={12} lg={0} xs={{...MWidth}}>
                                <div className="video-container"
                                     style={{marginLeft: '10%'}}>
                                    <Image
                                        src={mLifeLike_Img}
                                        preview={false}
                                    />
                                </div>
                            </Col>
                            <Col span={12} lg={10} xs={{...MWidth}}>
                                <div className="vertical-center-text">
                                    <h2 className="title-app"
                                        style={{
                                            color: 'white', ...TitleFont,
                                        }}>
                                        Lifelike Textures.
                                    </h2>
                                    <p className="text-content-app"
                                       style={{
                                           color: 'white',
                                           fontFamily: "Proxima Nova, sans-serif",
                                           fontWeight: '500',
                                       }}>
                                        {TEXTURA_TEXT['Lifelike Textures']}
                                    </p>
                                </div>
                            </Col>
                        </Row>


                        <Row justify={"center"}
                             style={{
                                 backgroundColor: '#f4f4f4',
                                 padding: '6rem 0 1rem 0',
                             }}
                             className={"img-text-zone"}
                        >
                            <Col span={10} lg={10}
                                 xs={{...MWidth}}
                                // xs={}
                                 style={{
                                     backgroundColor: '#ececec',
                                     textAlign: "center",
                                     borderTopLeftRadius: '5px',
                                     borderBottomLeftRadius: '5px',
                                 }}>
                                <div className="vertical-center-text" style={{marginLeft: '10%'}}>
                                    <h2 className="title-app"
                                        style={{
                                            color: 'black',
                                            ...TitleFont,
                                            textAlign: 'left'
                                        }}
                                    >
                                        Made for You.
                                    </h2>
                                    <p className="text-content-app" style={{color: 'black', width: '80%'}}>
                                        {TEXTURA_TEXT["Made for You"]}
                                    </p>
                                </div>
                            </Col>
                            <Col span={10} lg={{span: 10}} xs={{...MWidth}} style={{
                                backgroundColor: '#ececec',
                                borderTopRightRadius: '5px',
                                borderBottomRightRadius: '5px',
                            }}>
                                <div className="video-container" style={{display: "flex"}}>
                                    <Image
                                        src={MadeForYou_Img}
                                        preview={false}
                                        style={{
                                            borderTopRightRadius: '5px',
                                            borderBottomRightRadius: '5px',
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>

                        {/*Phone Section*/}
                        <Row justify={"center"}
                             style={{
                                 backgroundColor: '#f4f4f4',
                             }}
                             className={"img-text-zone m-img-text"}
                        >
                            <Col className={'m-padding-tb'} span={10} lg={10} xs={{...MWidth}}>
                                <div className="video-container"
                                     style={{
                                         background: 'linear-gradient(0deg, rgba(172,203,238,1) 4%, rgba(231,240,253,1) 100%)',
                                         borderRadius: '5px',
                                     }}>
                                    <Image
                                        src={Textura_App_Img}
                                        preview={false}
                                    />
                                </div>
                            </Col>
                            <Col span={10} lg={{span: 10, offset: 1}} xs={{...MWidth}}>
                                <div className="vertical-center-text" style={{margin: '0 0 0 0'}}>
                                    <h2 className="title-app" style={{
                                        color: 'black',
                                        ...TitleFont
                                    }}>
                                        Smart Screen for Your Smart Home.
                                    </h2>
                                    <p className="text-content-app" style={{
                                        color: 'black',
                                        fontFamily: "Proxima Nova, sans-serif",
                                        fontWeight: '500',
                                    }}>
                                        {TEXTURA_TEXT["Smart Screen for Your Smart Home"]}
                                    </p>
                                    <Row>
                                        <button type="submit"
                                                className={"pay-button"}
                                                onClick={() => {
                                                    window.location.href = 'https://apps.apple.com/gb/app/vieunite/id1644381065'
                                                }}
                                                style={{
                                                    marginTop: '1em', fontFamily: "Proxima Nova, sans-serif",
                                                    fontWeight: '800',
                                                    borderRadius: "10px",
                                                    padding: '1em 0 1em 0',
                                                }}

                                        >
                                            IOS APP STORE
                                        </button>
                                    </Row>
                                    <Row>
                                        <button type="submit"
                                                className={"pay-button"}
                                                onClick={() => {
                                                    window.location.href = 'https://play.google.com/store/apps/details?id=com.app.vieunite&gl=GB'
                                                }}
                                                style={{
                                                    marginTop: '1em', fontFamily: "Proxima Nova, sans-serif",
                                                    fontWeight: '800', borderRadius: "10px",
                                                    padding: '1em 0 1em 0',
                                                }}
                                        >
                                            GOOGLE PLAY STORE
                                        </button>
                                    </Row>
                                </div>
                            </Col>
                        </Row>


                        {/*Four Intro Cols*/}
                        <Row justify={"space-evenly"}
                             style={{
                                 backgroundColor: '#f4f4f4',
                                 flexWrap: 'wrap'
                             }}
                             className={"img-text-zone"}
                        >
                            <Col span={9} lg={4} xs={{...MWidth / 2}}>
                                <div className="text-container ">
                                    <h2 className="title-app primary-font" style={{
                                        color: 'black',
                                        display: "flex",
                                        justifyContent: "center",
                                        fontSize: '25px',
                                    }}>
                                        Simplicity
                                    </h2>
                                    <p className="text-content-app primary-font m-small-txt" style={{
                                        color: 'black',
                                        textAlign: "center", fontSize: '17px',
                                    }}>
                                        {TEXTURA_TEXT["Simplicity"]}
                                    </p>
                                </div>
                            </Col>
                            <Col span={8} lg={4} xs={{...MWidth / 2}}>
                                <div className="text-container ">
                                    <h2 className="title-app" style={{
                                        color: 'black',
                                        display: "flex",
                                        justifyContent: "center",
                                        fontSize: '25px',
                                    }}>
                                        Accuracy
                                    </h2>
                                    <p className="text-content-app m-small-txt" style={{
                                        color: 'black',
                                        textAlign: "center",
                                        fontSize: '17px',
                                    }}>
                                        {TEXTURA_TEXT["Accuracy"]}
                                    </p>
                                </div>
                            </Col>
                            <Col span={9} lg={4} xs={{...MWidth / 2}}>
                                <div className="text-container ">
                                    <h2 className="title-app" style={{
                                        color: 'black',
                                        display: "flex",
                                        justifyContent: "center",
                                        fontSize: '25px',
                                    }}>
                                        Efficiency
                                    </h2>
                                    <p className="text-content-app m-small-txt" style={{
                                        color: 'black',
                                        textAlign: "center",
                                        fontSize: '17px',
                                    }}>
                                        {TEXTURA_TEXT["Efficiency"]}
                                    </p>
                                </div>
                            </Col>
                            <Col span={8} lg={4} xs={{...MWidth / 2}}>
                                <div className="text-container ">
                                    <h2 className="title-app" style={{
                                        color: 'black',
                                        display: "flex",
                                        justifyContent: "center",
                                        fontSize: '25px',
                                    }}>
                                        Transparency
                                    </h2>
                                    <p className="text-content-app m-small-txt" style={{
                                        color: 'black',
                                        textAlign: "center",
                                        fontSize: '17px',
                                    }}>
                                        {TEXTURA_TEXT["Transparency"]}
                                    </p>
                                </div>
                            </Col>
                        </Row>

                        {/*Get Support Section*/}
                        <Row justify={"center"}
                             style={{
                                 backgroundColor: '#f4f4f4',
                                 padding: '2rem 0',
                             }}
                             className={"img-text-zone"}
                        >
                            <Col span={20} lg={20} xs={{...MWidth}} style={{
                                backgroundColor: 'black',
                                borderRadius: '5px',
                                paddingBottom: '2.75rem',
                                paddingTop: '2.75rem',
                            }}>
                                <div>
                                    <h2 className="title-app m-text"
                                        style={{
                                            color: 'white',
                                            display: "flex",
                                            justifyContent: "center",
                                            ...TitleFont,
                                            margin: '0 1rem',
                                        }}>
                                        Get Support.
                                    </h2>
                                    <p className="text-content-app m-text"
                                       style={{
                                           color: 'white',
                                           textAlign: "center",
                                           paddingLeft: '4rem',
                                           paddingRight: '4rem',
                                           margin: '0 1rem',
                                       }}>
                                        {TEXTURA_TEXT["Get Support"]}
                                        <a href="mailto:info@vieunite.com"
                                           style={{color: "white", textDecoration: "underline"}}>
                                            support@vieunite.com.
                                        </a>
                                    </p>
                                </div>
                            </Col>
                        </Row>

                        {/*Google Reviews*/}
                        <Row className={'m-img-art'} justify={'center'}
                             id={'REVIEWS'}
                             style={{padding: '2rem 0 1rem 0', background: '#f4f4f4'}}>
                            <Col span={10} lg={{span: 20}} xs={20}>
                                <h2 className="title-app mobile-left"
                                    style={{textAlign: "left", ...TitleFont}}>
                                    Reviews.
                                </h2>
                                <p className="text-content-app mobile-left artist-text"
                                   style={{
                                       ...PrimaryFont, fontWeight: '500',
                                       fontSize: '1rem', marginTop: '-1rem'
                                   }}>
                                    Read reviews of Vieunite on <a
                                    href='https://www.google.com/search?gs_ssp=eJzj4tVP1zc0LLZMyU6vNC02YLRSNagwsTA3sDQ3T022MDA1MDYxtTKosDQ0TTFOTjGyTEu0NDVKsfDiKMtMLc3LLEkFADVZEi0&q=vieunite&rlz=1C5CHFA_enGB1000GB1000&oq=vieunite&aqs=chrome.1.69i60j46i175i199i512j35i39j0i512j69i60l4.3900j0j7&sourceid=chrome&ie=UTF-8'
                                    style={{color: "black", textDecoration: "underline"}}>Google</a>.
                                </p>
                            </Col>
                        </Row>
                        <Row justify={"center"} align={"top"}
                             style={{background: '#f4f4f4', columnGap: '1rem', paddingBottom: '4rem'}}>
                            {/*Left part*/}
                            <Col span={20} lg={10} xs={{...MWidth}} style={{
                                backgroundColor: '#f4f4f4',
                                // paddingBottom: '2.75rem',
                                // paddingTop: '2.75rem',
                            }}>
                                <Space direction={"vertical"} size={'large'}>
                                    <ReviewCard reviewer={Reviews[0]}></ReviewCard>
                                    <ReviewCard reviewer={Reviews[1]}></ReviewCard>
                                    <ReviewCard reviewer={Reviews[2]}></ReviewCard>
                                </Space>
                            </Col>

                            {/*Right part*/}
                            <Col className={'right-review-card'} span={20} lg={10} xs={{...MWidth}} style={{
                                backgroundColor: '#f4f4f4',
                            }}>
                                <ReviewCard reviewer={Reviews[3]}></ReviewCard>
                            </Col>
                        </Row>

                        {/*Collapsable Sections*/}
                        <Row justify={"center"}
                             style={{
                                 backgroundColor: '#f4f4f4',
                             }}>
                            <Col span={20} lg={20} md={20}>
                                <Collapse expandIconPosition={"end"}
                                          bordered={false}
                                          style={{
                                              backgroundColor: '#f4f4f4',
                                              color: '#f4f4f4',
                                              border: "none"
                                          }}
                                          expandIcon={() => <PlusOutlined style={{
                                              fontSize: '24px', fontWeight: "900",
                                          }}/>}
                                >
                                    <Panel header="Description"
                                           style={{
                                               fontSize: '24px', fontWeight: "700",
                                           }}
                                           key="1">
                                        <p>{PRODUCT_TEXT.Description}</p>
                                        <p>{PRODUCT_TEXT.Description_Dimensions}</p>
                                    </Panel>
                                    <Panel header="Delivery" key="2" style={{
                                        fontSize: '24px', fontWeight: "700",
                                    }}>
                                        <p>{PRODUCT_TEXT.Delivery}</p>
                                    </Panel>
                                    <Panel header="Return Policy" key="3" style={{
                                        fontSize: '24px', fontWeight: "700",
                                    }}>
                                        <p>
                                            {PRODUCT_TEXT["Return Policy"]}
                                            <a href="mailto:info@vieunite.com"
                                               style={{color: "black", textDecoration: "underline"}}>
                                                support@vieunite.com.
                                            </a>
                                        </p>
                                    </Panel>
                                    <Panel header="In the Box" key="4" style={{
                                        fontSize: '24px', fontWeight: "700",
                                    }}>
                                        <p>{PRODUCT_TEXT["In the Box"]}</p>
                                    </Panel>
                                    <Panel header="Downloads" key="6" style={{
                                        fontSize: '24px', fontWeight: "700",
                                    }}>
                                        <a href={"/Vieunite - VT27WGV1 - Version 1.0 User Manual.pdf"}>
                                            Vieunite - VT27WGV1 - Version 1.0 User Manual
                                        </a>
                                        <br></br>
                                        <a href={"/VT27WGV1 - Technical Drawing.pdf"}>
                                            VT27WGV1 - Technical Drawing
                                        </a>
                                    </Panel>
                                    <Panel header="Warranty" key="5" style={{
                                        fontSize: '24px', fontWeight: "700",
                                    }}>
                                        <p>{PRODUCT_TEXT.Warranty}</p>
                                    </Panel>
                                </Collapse>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </div>
        )
    }
}

export default ProductDetail;