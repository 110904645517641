import {message} from 'antd';
import {getFileNameWithoutExtension} from "./utils/fileOps";

// const serverPath = 'http://192.168.1.140:8098'
const serverPath = 'https://api.vieunite.com/photoframe-business-414uiupopcdc121yuy'

export const get_api = (reqUrl) => {
    const token = localStorage.getItem('token')
    if (token === '' || token === null || token.length === 0) {
        window.location.href = '/business/prosignin'
    }
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("X-Access-Token", token);
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(serverPath + reqUrl, requestOptions)
        .then(response => {
            if (response.status === 446) {
                localStorage.clear();
                window.location.href = '/business/prosignin'
                throw new Error('HTTP ERROR: Please login!');
            } else {
                if (response.status !== 200) {
                    return response.json().then(ret => {
                        throw new Error('HTTP ERROR: ' + ret);
                    });
                } else {
                    return response.json()
                }
            }
        })
        .then(result => {
            console.log(result)
            return result.result;
        })
        .catch(error => console.log('error', error));
}
export const post_api_upload = (name, md5 = '1', file, needStep2 = false) => {
    const token = localStorage.getItem('token')
    if (token === '' || token === null || token.length === 0) {
        window.location.href = '/business/prosignin'
    }
    let myHeaders = new Headers();
    // myHeaders.append("Accept", "application/json");
    // myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-Access-Token", token);
    const encodedFileName = encodeURIComponent(name);
    myHeaders.append("Filename", encodedFileName);
    // myHeaders.append("Filename", name);
    myHeaders.append("IDcard", md5);
    const formData = new FormData();
    formData.append('file', file);

    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
    };

    return fetch(serverPath + '/storage/store/file', requestOptions)
        .then(response => {
            if (response.status === 422) {
                return response.json().then(ret => {
                    message.error('Value Error' + ret.detail[0]['msg']);
                    throw new Error('HTTP ERROR 422: ' + ret.detail[0]['msg']);
                });
            }
            if (response.status === 444 || response.status === 445 || response.status === 446) {
                return response.json().then(ret => {
                    message.error(ret.detail, 6);
                    throw new Error('HTTP ERROR: ' + ret.detail);
                });
            } else {
                return response.json()
            }
        })
        .then(result => {
            console.log(result)
            if (result.code === 200) {
                return result.result;
            } else {
                message.error('Server side error!')
                throw new Error('HTTP ERROR');
            }
        }).then(result => {
            console.log(result)
            return [result?.storage_id, result?.picture_uri];
        })
        .then(async (res) => {
            if (needStep2) {
                const data = {
                    storage_id: res[0],
                    name: getFileNameWithoutExtension(name),
                    description: 'My Uploaded Arts'
                }
                await post_api_upload_step2(data)
            } else {
                return res[1]
            }
        })
}

const post_api_upload_step2 = (data) => {
    const token = localStorage.getItem('token')
    if (token === '' || token === null || token.length === 0) {
        window.location.href = '/business/prosignin'
    }
    return fetch(serverPath + '/artwork/upload/private/artwork', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "X-Access-Token": token
        },
        body: JSON.stringify(data)
    })
        .then(response => response.json())
        .then(data => console.log(data))
        .then(data => {
            message.success('Successful!');
        })
    // .catch(error => console.error('Error:', error));
}

const NO_TOKEN_REQ = [
    '/user/login',
    '/user/register',
    '/user/verify/email/last/step',
    '/user/send/change/password/link',
    '/user/send/verify/email/link',
    '/user/get/login/email/code',
    '/user/get/login/email/code',
    '/user/change/password',
]
export const post_api = (reqUrl, data) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    if (!NO_TOKEN_REQ.includes(reqUrl)) {
        const token = localStorage.getItem('token')
        if (token === '' || token === null || token.length === 0) {
            window.location.href = '/business/prosignin'
        } else {
            myHeaders.append("X-Access-Token", token);
        }
    }


    var raw = JSON.stringify(data);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    return fetch(serverPath + reqUrl, requestOptions)
        .then(response => {
            if (response.status === 422) {
                return response.json().then(ret => {
                    message.error(ret.detail[0]['msg']);
                    throw new Error('HTTP ERROR 422: ' + ret.detail[0]['msg']);
                });
            }
            if (response.status === 444 || response.status === 445 || response.status === 446) {
                return response.json().then(ret => {
                    message.error(ret.detail);
                    throw new Error('HTTP ERROR: ' + ret.detail);
                });
            } else {
                return response.json()
            }
        })
        .then(result => {
            if (result.code === 200) {
                message.success('Successful!');
            } else {
                message.error('Server side error!')
                throw new Error('HTTP ERROR');
            }
            return result.result;
        })
}
export const post_api_pay_textura = (reqUrl, data) => {
    const token = localStorage.getItem('token')
    if (token === '' || token === null || token.length === 0) {
        window.location.href = '/business/prosignin'
    }
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("X-Access-Token", "2312");

    var raw = JSON.stringify(data);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    return fetch(serverPath + reqUrl, requestOptions)
        .then(response => {
            if (response.status === 422) {
                return response.json().then(ret => {
                    message.error('Value Error' + ret.detail[0]['msg']);
                    throw new Error('HTTP ERROR 422: ' + ret.detail[0]['msg']);
                });
            } else {
                return response.json()
            }
        })
        .then(result => {
            if (result.code === 200) {
                return result.result;
            } else {
                message.error(result.message ? result.message : 'Server side error!')
                throw new Error(result?.message)
            }
        })
}