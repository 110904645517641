import React, {useState} from 'react';
import './MobileMenu.scss';
import {ReactComponent as Logo} from "../../img/SVG/Vieunite_logo_Text-black-01.svg";
import {Divider} from "antd";
import {useNavigate} from "react-router-dom";

export default function MobileMenu() {
    return (
        <>
            <h1>
                <a href="/home"><Logo style={{marginTop:'2vh'}}/></a>
            </h1>

            <input id="burger" type="checkbox"/>

            <label htmlFor="burger">
                <span></span>
                <span></span>
                <span></span>
            </label>

            <nav>
                <ul>
                    <li><a href="/digital-canvas">Textura</a><Divider className={'m-menu-divider'}/></li>
                    <li><a href="/artists">Vieunite Artists</a><Divider className={'m-menu-divider'}/></li>
                    <li><a href="/news">News</a><Divider className={'m-menu-divider'}/></li>
                    <li><a href="/contact">Contact Us</a><Divider className={'m-menu-divider'}/></li>
                    <li onClick={() => {
                        if ((/Android/i.test(navigator.userAgent))) {
                            window.location.href = 'https://play.google.com/store/apps/details?id=com.app.vieunite&gl=GB';
                        }
                        if (/iPad|iPhone|iPod/i.test(navigator.userAgent)) {
                            window.location.href = 'https://apps.apple.com/gb/app/vieunite/id1644381065';
                        }
                    }}><a>Vieunite App</a><Divider className={'m-menu-divider'}/></li>
                    {/*<li>*/}
                    {/*    <a href={'/artistportal'}>Artist Portal</a>*/}
                    {/*</li>*/}
                    <li><a href="/artistportal">Artist Portal</a><Divider className={'m-menu-divider'}/></li>
                    <li style={{flexGrow: 1}}></li>
                    {/* This will push the last <li> to the bottom */}
                    <li style={{marginBottom: '16vh'}}>
                        <Divider className={'m-menu-divider'}/>
                        <a href="/business" style={{display: "flex"}}>
                            Vieunite for Business
                            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="30" viewBox="0 0 33 30"
                                 fill="none" style={{
                                margin: "auto"
                            }}>
                                <path
                                    d="M31.8966 16.4943C32.6777 15.7132 32.6777 14.4469 31.8966 13.6659L19.1687 0.937943C18.3877 0.156894 17.1213 0.156894 16.3403 0.937943C15.5592 1.71899 15.5592 2.98532 16.3403 3.76637L27.654 15.0801L16.3403 26.3938C15.5592 27.1748 15.5592 28.4412 16.3403 29.2222C17.1213 30.0033 18.3877 30.0033 19.1687 29.2222L31.8966 16.4943ZM2.48242 13.0801C1.37785 13.0801 0.482422 13.9755 0.482422 15.0801C0.482422 16.1846 1.37785 17.0801 2.48242 17.0801V13.0801ZM30.4824 13.0801L2.48242 13.0801V17.0801L30.4824 17.0801V13.0801Z"
                                    fill="#105B63"/>
                            </svg>
                        </a>
                    </li>
                </ul>
            </nav>
        </>
    )
}