import React, { useState } from 'react';
import {Button, Col, Modal, Row} from 'antd';
import {Primary_Font} from "../../../../../settings";
import {PortalHomeTxt} from "../../text";
import {ReactComponent as EmailIcon} from "../../../../../img/SVG/email.svg";
import {ReactComponent as PhoneIcon} from "../../../../../img/SVG/phone-call.svg";
import useWindowResize from "../../../../Hooks/useWindowResize";

const SupportCard = ()=>{
    const {windowWidth} = useWindowResize()
    const {windowHeight} = useWindowResize()
    return (
        <div className="small-card"  style={{ fontSize: windowWidth > 600 ? windowWidth / 100 * 0.65: windowWidth / 100*3.5}}>
            <p className={'card-title'}  style={{fontSize: windowWidth > 600 ? windowWidth / 100 * 1.6 : windowWidth / 100 * 8., marginBottom: windowHeight / 100 * 3.2}}>
                Vieunite <span style={{fontWeight: '600'}}>Support</span>.
            </p>
            <p className={'m-none'} style={{color: "black", marginTop: '-10%', marginBottom:'8%',cursor: "pointer"}}>
                {PortalHomeTxt["support"]}
            </p>
            <Row justify={"center"}>
                <Col span={6} lg={6}>
                    <PhoneIcon style={{transform:'scale(2)'}}/>
                </Col>
                <Col span={10} lg={10}>
                    <p style={{...Primary_Font,
                        color: "black",
                        lineHeight:'0.4rem',
                        fontWeight:'600'}}>
                        Call Us
                    </p>
                    <p style={{color: "black", lineHeight:'0.2rem'}}>
                        <a style={{color: "black"}} href="tel:03455-481311">{'03455-481311'}</a>
                    </p>
                </Col>
            </Row>
            <Row justify={"center"} style={{marginTop:'1rem'}}>
                <Col span={6}>
                    <EmailIcon style={{transform:'scale(2)'}}/>
                </Col>
                <Col span={10}>
                    <p style={{...Primary_Font,
                        color: "black",
                        lineHeight:'0.4rem',
                        fontWeight:'600'}}>
                        Email Us
                    </p>
                    <p style={{color: "black", lineHeight:'0.2rem'}}>
                        <a style={{color: "black"}} href="mailto:info@vieunite.com">{'info@vieunite.com'}</a>
                    </p>
                </Col>
            </Row>
        </div>
    )
}

export default SupportCard;