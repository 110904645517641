import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Col, Dropdown, Layout, Menu, Row, Select, Space} from "antd";
import {Content} from "antd/es/layout/layout";
import './style.scss'
import {useLocation} from "react-router-dom";
import ProPortalHeader from "../../componets/ProPortalHeader";
import ProfileRow from "../../componets/ProfileRow";
import Navigator from "../../componets/Navigator";
import {DownOutlined} from "@ant-design/icons";
import SubMenu from "antd/es/menu/SubMenu";
import {get_api} from "../../serverTemp";

const SingleAlbumDetails = (props) => {
    const {state} = useLocation()
    const {albumID, title} = state
    console.log(state)
    const [images, setImages] = useState()
    useEffect(() => {
        get_api(`/album/artworks?album_id=${albumID}`).then(
            res => {
                setImages(res.data)
                console.log(res.data)
            }
        )
    }, [])
    return (
        <Layout>
            <Content>
                <ProPortalHeader/>
                <ProfileRow/>
                <Row>
                    <Layout>
                        <Navigator/>
                        <Layout>
                            <Col lg={24} style={{}}>
                                <Row style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                }}>
                                    <p className={'mb-dsp-title'}>{title}</p>
                                </Row>
                                <Row justify={"center"} style={{
                                    marginBottom: '40rem'
                                }}>
                                    {images?.map((img, idx) =>
                                        <div className={'mb-spec-img-div'}>
                                            <img
                                                className={'mb-spec-img-div-image'}
                                                src={img.url}
                                                alt={'img'}
                                                style={
                                                    img.height > img.width ?
                                                        {height: "60%", width: "auto"} :
                                                        {height: "auto", width: "80%",margin:'6vh 0 3vh 0'}
                                                }
                                            />
                                            <p className={'mb-spec-img-div-p'}>
                                                {img.name}
                                            </p>
                                            <Checkbox
                                                clasName={'single-album-ck'}
                                                onChange={(e) => {
                                                    console.log(`checked = ${e.target.checked}`)
                                                }}/>

                                        </div>
                                    )}

                                </Row>
                            </Col>
                        </Layout>
                    </Layout>
                </Row>
            </Content>
        </Layout>
    )
}

export default SingleAlbumDetails;
