import React, {useEffect, useState} from 'react';
import {Button, Col, Input, Layout, message, Modal, Row, Spin, Tooltip, Upload} from "antd";
import {Content} from "antd/es/layout/layout";
import './style.scss'
import ProPortalHeader from "../componets/ProPortalHeader";
import Navigator from "../componets/Navigator";
import ProfileRow from "../componets/ProfileRow";
import {UploadIcon, BSUploadIcon, BSIcon} from "./icons";
import curated_img from './imgs/Curated Collection Tile.webp'
import {ReactComponent as LandscapeIcon} from './imgs/Landscape icon.svg'
import {ReactComponent as PortraitIcon} from './imgs/Portrait icon.svg'
import AlbumCoverLayout from "../ProDiscovery/AlbumCoverLayout";
import CoverLayout from "../ProDiscovery/AlbumCoverLayout";
import {get_album_data, get_all_arts} from "./services";
import {ReactComponent as DelIcon} from './imgs/Delete.svg'
import {ReactComponent as PubIcon} from './imgs/Publish.svg'
import {ReactComponent as RenameIcon} from './imgs/Rename.svg'
import {useNavigate} from "react-router-dom";
import {get_api, post_api, post_api_upload} from "../serverTemp";
import MBModal from "./MBModal";
import left from "../ProDiscovery/images/left.png";
import upRight from "../ProDiscovery/images/upRight.png";
import downRight from "../ProDiscovery/images/downRight.png";
import SparkMD5 from "spark-md5";
import {getMD5} from "../../apps/ArtistPortal/ArtistPortalHome/components/ArtUploader/utils";
import {PlusCircleOutlined} from "@ant-design/icons";

const MyLibrary = (props) => {
    const [albums, setAlbums] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isNewAlbum, setIsNewAlbum] = useState(true);
    const [curAlbumID, setCurAlbumID] = useState('')
    const navigate = useNavigate()
    const [albumName, setAlbumName] = useState(''); // State to store the input value
    const [uploading, setUploading] = useState(false);


    const handleInputChange = (e) => {
        setAlbumName(e.target.value); // Update state with input value
    };

    const handleConfirmClick = () => {
        if (isNewAlbum) {
            post_api('/album/add', {name: albumName}).then(
                res => {
                    window.location.reload()
                })
        } else {
            post_api('/album/rename', {id: curAlbumID, name: albumName}).then(
                res => {
                    window.location.reload()
                })
        }
    };
    const MBModalprops = {
        isModalOpen: isModalOpen,
        setIsModalOpen: setIsModalOpen,
        handleConfirmClick: handleConfirmClick,
        handleInputChange: handleInputChange,
        albumName: albumName,
        isNewAlbum: isNewAlbum,
    }
    useEffect(() => {
        get_api('/album/all').then(
            res => {
                setAlbums(res.data.map((album) => {
                    return {
                        id: album.id,
                        name: album.name,
                        urls: album.url,
                        item: {
                            left: album.url.split(',')[0] ?? null,
                            upRight: album.url.split(',')[1] ?? null,
                            downRight: album.url.split(',')[2] ?? null,
                        }
                    }
                }))
            }
        )
    }, [])
    const uploadFile = async (blob) => {
        setUploading(true)
        console.log(blob.name)
        const spark = new SparkMD5.ArrayBuffer();
        let fileMd5 = '';
        await getMD5(blob, spark).then((md5) => {
            fileMd5 = md5
        })
        post_api_upload(
            blob.name,
            fileMd5,
            blob,
            true
        ).then(() => {
            setUploading(false)
        }).catch(error => {
            console.log('error', error)
            if (error.toString().startsWith('HTTP ERROR')) {
                message.error(error)
            } else {
                message.warn('Failed to upload the file.')
            }
            setUploading(false)
        });
    }
    return (
        <Layout>
            <Content>
                <ProPortalHeader/>
                <ProfileRow/>
                <Row>
                    <Layout>
                        <Navigator/>
                        <Layout>
                            <Col lg={24}>
                                <p className={'ml-albums-p'} style={{marginTop: '3rem'}}>Manage Content</p>
                                <Row justify={"space-evenly"} style={{margin: '2rem 0 2rem 0'}}>
                                    <div className={'ml-card-divs'}>
                                        <p className={'ml-upload-txt'}>Upload Media</p>

                                        <Upload
                                            showUploadList={false}
                                            maxCount={10} //限制上传数量。当为 1 时，始终用最新上传的文件代替当前文件
                                            multiple={true} //是否支持多选文件，开启后按住 ctrl 可选择多个文件
                                            accept={'.png,.jpg,.jpeg, .mp4'}
                                            // beforeUpload={_ => false}
                                            customRequest={({file}) => {
                                                uploadFile(file)
                                            }}
                                            disabled={uploading}
                                        >
                                            <div className={'ml-up-div'}>
                                                {uploading ?
                                                    <Spin tip="Uploading" size="large"/>
                                                    : <BSUploadIcon style={{opacity: '0.5'}}/>}
                                            </div>
                                            <Button className={'ml-up-btn'} loading={uploading}>
                                                UPLOAD FILES
                                            </Button>
                                        </Upload>
                                        <p className={'ml-format-txt'}>
                                            Supported formats: JPEG, PNG, JPG or MP4
                                        </p>
                                    </div>
                                    <div className={'ml-card-divs'}
                                         onClick={() => {
                                             navigate('/proportal/images',
                                                 {
                                                     state: {
                                                         filter: -1,
                                                         title: 'My Uploads'
                                                     }
                                                 })
                                         }}
                                    >
                                        <UploadIcon/>
                                        <p className={'ml-card-divs-title'} style={{margin: '-20px 0 20px 0'}}>
                                            My Uploads
                                        </p>
                                        <p className={'ml-card-divs-des'}>
                                            Organise files from your most recent upload
                                        </p>
                                    </div>
                                    <div className={'ml-card-divs'}
                                         onClick={() => {
                                             navigate('/proportal/images',
                                                 {
                                                     state: {
                                                         filter: 2,
                                                         title: 'My Vieunite Art',
                                                         myArtMode: true
                                                     }
                                                 })
                                         }}
                                    >
                                        <BSIcon style={{}}/>
                                        <p className={'ml-card-divs-title'} style={{marginTop: '0px'}}>
                                            My Vieunite Art
                                        </p>
                                        <p className={'ml-card-divs-des'}>
                                            View all the artwork
                                            in your collection
                                        </p>
                                    </div>
                                    <div className={'ml-card-divs'}
                                         onClick={() => {
                                             navigate('/proportal/albums')
                                         }}
                                    >
                                        <img style={{width: '16rem', height: '12rem'}}
                                             src={curated_img}
                                             alt={'curated_img'}
                                        />
                                    </div>
                                </Row>
                                <Row className={'ml-albums-p'} style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '85%',
                                    marginTop: '6vh'
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row"
                                    }}>
                                        <span>My Albums</span>
                                        <Tooltip title="Create a new album">
                                            <PlusCircleOutlined
                                                className={'ml-plus-icon'}
                                                onClick={() => {
                                                    setIsNewAlbum(true)
                                                    setIsModalOpen(true)
                                                }}/>
                                        </Tooltip>
                                    </div>
                                    {/*<Button className={'ml-add-album-btn'}*/}
                                    {/*        onClick={() => {*/}
                                    {/*            setIsNewAlbum(true)*/}
                                    {/*            setIsModalOpen(true)*/}
                                    {/*        }}*/}
                                    {/*>*/}
                                    {/*<span style={{*/}
                                    {/*    fontSize:'1.5vw',*/}
                                    {/*    marginTop:'-6px'*/}
                                    {/*}}>+</span>*/}
                                    {/*<span>Create a new album</span>*/}

                                    {/*</Button>*/}
                                    <span style={{
                                        cursor: 'pointer',
                                        opacity: '0.75',
                                        fontWeight: '400'
                                    }}
                                          onClick={_ => {
                                              window.location.href = '/proportal/my-albums'
                                          }}
                                    >See All</span>
                                </Row>
                                <Row justify={"space-evenly"}
                                     style={{paddingBottom: '8rem'}}
                                >
                                    {albums.length >= 0 ?
                                        albums.slice(0, 4).map((album, index) => {
                                            return (
                                                <div className={'mb-album-card'} style={{
                                                    margin: 0
                                                }}>
                                                    {album?.urls?.split(',').length >= 3 ?
                                                        <div className="container"
                                                             onClick={() => {
                                                                 navigate('/proportal/images', {
                                                                     state: {
                                                                         albumID: album.id,
                                                                         // albumID:`${localStorage.getItem('user_id')}_default`,
                                                                         title: album.name,
                                                                         filter: 'album'
                                                                     }
                                                                 })
                                                             }}
                                                             style={{
                                                                 width: 200,
                                                                 height: 180,
                                                                 marginLeft: 10,
                                                                 marginTop: 10,
                                                                 marginRight: 10,
                                                                 marginBottom: 0,
                                                                 cursor: 'pointer',
                                                             }}
                                                        >
                                                            <div className="left">
                                                                <img style={{height: '99%'}}
                                                                     src={album?.item.left || left} alt="Left"/>
                                                            </div>
                                                            <div className="right">
                                                                <img src={album?.item.upRight || upRight}
                                                                     alt="Top Right"/>
                                                                <img src={album?.item.downRight || downRight}
                                                                     alt="Bottom Right"/>
                                                            </div>
                                                        </div>
                                                        :
                                                        <img
                                                            onClick={() => {
                                                                navigate('/proportal/images', {
                                                                    state: {
                                                                        albumID: album.id,
                                                                        title: album.name,
                                                                        filter: 'album'
                                                                    }
                                                                })
                                                            }}
                                                            src={album.urls.split(',')[0]} alt="Album cover"
                                                            style={{
                                                                width: "80%",
                                                                height: "50%",
                                                                objectFit: "cover",
                                                                cursor: 'pointer',
                                                            }}/>
                                                    }
                                                    <p className={'mb-album-name'}>
                                                        {album?.name}
                                                    </p>
                                                    <div className={'mb-album-ops'}>
                                                        <div style={{
                                                            cursor: 'pointer', display: 'flex',
                                                            alignItems: 'center',
                                                            flexDirection: 'column',
                                                        }}
                                                             onClick={() => {
                                                                 navigate('/proportal/dashboard', {state: {album_id: album.id}})
                                                             }}
                                                        >
                                                            <PubIcon/>
                                                            <p className={'ml-p-small-icon'}>PUBLISH</p>
                                                        </div>
                                                        <div style={{
                                                            cursor: 'pointer', display: 'flex',
                                                            alignItems: 'center',
                                                            flexDirection: 'column',
                                                        }}
                                                             onClick={() => {
                                                                 setIsNewAlbum(false)
                                                                 setCurAlbumID(album.id)
                                                                 setIsModalOpen(true)
                                                             }}>
                                                            <RenameIcon/>
                                                            <p className={'ml-p-small-icon'}>RENAME</p>
                                                        </div>
                                                        <div style={{
                                                            cursor: 'pointer',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            flexDirection: 'column',
                                                        }}
                                                             onClick={() => {
                                                                 console.log(album.id)
                                                                 post_api('/album/delete', {
                                                                     id: album.id
                                                                 }).then(
                                                                     res => {
                                                                         window.location.reload()
                                                                     }
                                                                 )
                                                             }}>
                                                            <DelIcon/>
                                                            <p className={'ml-p-small-icon'}>DELETE</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) :
                                        <></>
                                    }
                                    {albums.length < 4 && [...Array(4 - albums.length)].map(() =>
                                        <div style={{
                                            width: "275px",
                                            height: "auto",
                                            objectFit: "cover",
                                            // cursor: 'pointer',
                                            background: "#f0f2f5"
                                            // background: "black"
                                        }}/>)}

                                </Row>
                            </Col>
                            <MBModal {...MBModalprops}/>
                        </Layout>
                    </Layout>
                </Row>

            </Content>
        </Layout>
    )
}

export default MyLibrary;
