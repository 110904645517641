import {Col, Row, Space} from "antd";
import {StarFilled} from "@ant-design/icons";
import React from "react";
import './Reviews.css'

export const Reviews=[
    {
        name:'Mawgen',
        date:'Reviewed on September 21st 2022',
        reviews: ['The Textura digital canvas looks amazing whether you’re displaying artwork or family photos. Also really impressed with the app, it’s like an online art gallery!',],
    },
    {
        name:'Chris Bevan',
        date:'Reviewed on September 21st 2022',
        reviews: ['This is a truly inspirational digital canvas that brings art to life.',],
    },
    {
        name:'iYOJA LTD',
        date:'Reviewed on September 21st 2022',
        reviews: ['I went to see the Vieunite exhibition. Beautiful product.',],
    },
    {
        name:'Callum Jackson',
        date: 'Reviewed on September 21st 2022',
        reviews: ['The Vieunite digital canvas is such a great medium to show artwork, especially as more & more digital art is being created.',
            'The most impressive thing for me is how well it displays textures and colours - I was genuinely surprised to find out it was a screen when I first saw it! The Vieunite app is really good too, it has a great selection of artwork to put onto the canvas, and it\'s easy to use.',
            'It gets a 10/10 from me.',
        ],
    },
]
const PrimaryFont = {
    fontFamily: "Proxima Nova bold, sans-serif",
}
export function ReviewCard(props){
    const reviewer = props.reviewer;
    return(
        <div style={{
            background:"white",
            borderRadius: '5px',
            paddingBottom: '0rem',
            paddingTop: '1.3rem',
        }}>
            <Space direction={"vertical"} size={"middle"}>
                <Row justify={"center"}>
                    <Col span={20}>
                        {[...Array(5)].map(() => <StarFilled
                            style={{fontSize: '2.1em', color: "#ffd202"}}/>)}
                    </Col>
                </Row>
                <Row justify={"center"}>
                    <Col span={20}>
                        <p
                            style={{
                                color: 'black',
                                // display: "flex",
                                // justifyContent: "center",
                                ...PrimaryFont,
                                fontSize: '.9rem'
                            }}>
                            <b>{reviewer.name}</b> <span style={{color: "grey"}}> | </span>
                            <span style={{color: "grey"}}>{reviewer.date}</span>
                        </p>
                    </Col>
                </Row>
                <Row justify={"center"}>
                    <Col span={20} className={'review-card-col'}>
                        {reviewer.reviews.map(
                            (val,i) =>{
                                if (i+1===reviewer.reviews.length){
                                    return(
                                        <p className=""
                                           style={{
                                               ...PrimaryFont,
                                               color: 'black',
                                               fontWeight: '500',
                                               fontSize: '1rem',
                                               marginTop: 0,
                                               textAlign: "left",
                                               minWidth:'100%',
                                               // width:'100%',
                                               // lineHeight:'0rem'
                                           }}>
                                            {val}
                                            <span style={{
                                                color:"white",
                                                userSelect:"none",
                                            }}>
                                            This is a truly inspirational digital canvas that brings art to life.</span>
                                        </p>
                                    )
                                }
                                else{
                                    return(
                                        <p className=""
                                           style={{
                                               ...PrimaryFont,
                                               color: 'black',
                                               fontWeight: '500',
                                               fontSize: '1rem',
                                               marginTop: 0,
                                               textAlign: "left",
                                               minWidth:'100%',
                                               // width:'100%',
                                           }}>
                                            {val}
                                        </p>
                                    )
                                }
                            }
                        )}
                    </Col>
                </Row>
            </Space>
        </div>
    )
}